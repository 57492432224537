import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { formatDateTime, convertCurrency } from '../../../utils/helpers';
import queryString from 'query-string';
import moment from 'moment';
import { TRANSACTION_STATUS_BUYER_RECEIVE, TRANSACTION_STATUS_ON_HOLD } from '../../../utils/constants';
import { updateTransaction } from '../../../actions/transaction';

const statuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Paid',
  'Completed',
  'Refund',
  'Fail',
  'Delivered',
  'Suspended',
  'On Hold',
  'Partial Refund',
  'Processing Payment',
];

const statusesColor = [
  'default',
  'warning',
  'warning',
  'success',
  'success',
  'warning',
  'danger',
  'success',
  'danger',
  'success',
  'success',
  'success',
];

const TransactionsTable = ({ data, settings, pageSize = 10, initialPage = 1, onPageChange }) => {
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showProofModal, setShowProofModal] = useState(false);
  const [imageOrientations, setImageOrientations] = useState({});

  const onChangeOnHold = async(method) => {
    const result = window.confirm(`Are you sure you want to change the status of order ${method._id} to On Hold?`);
    if (result) {
      dispatch(updateTransaction(method?.tx_id, {
        status: TRANSACTION_STATUS_BUYER_RECEIVE,
      }, () => {
        handlePageChange(1);
      }));
    }
  }

  const handleViewChat = (order) => {
    setSelectedOrder(order);
    setShowChatModal(true);
  };

  const handleViewProof = (order) => {
    setSelectedOrder(order);
    setShowProofModal(true);
  };

  const handleCloseChatModal = () => {
    setShowChatModal(false);
    setSelectedOrder(null);
  };

  const handleCloseProofModal = () => {
    setShowProofModal(false);
    setSelectedOrder(null);
  };

  const handleImageLoad = (index, event) => {
    const img = event.target;
    const isLandscape = img.naturalWidth > img.naturalHeight;
    setImageOrientations(prev => ({
      ...prev,
      [index]: isLandscape ? 'landscape' : 'portrait'
    }));
  };

  const columns = [
    {
      accessorKey: 'order',
      header: 'ORDER',
      cell: ({ row }) => {
        const method = row.original;
        return (
          <div className="text-body text-hover-1000">
            <div className="mb-0">
              <strong>Order Date</strong>: {formatDateTime(method.created_time * 1000)}
              <br />
              <strong className="">Order ID</strong>: <span className="_id">{method._id}</span>
              <br />
              <strong className="">Transaction ID</strong>:{' '}
              <span className="merchant_trade_no">{method.merchant_trade_no}</span>
              <br />
              <strong>Order Title</strong>:{' '}
              <span className="item_name">
                {method.item_name} <strong className="text-danger"> x {method.amount}</strong>{' '}
              </span>
              <br />
              <strong>
                {method.category_name} - {method.product_type_name}
              </strong>
              <br />
            </div>
            <button
              className="btn btn-info btn-sm ms-2"
              onClick={() => handleViewChat(method)}
            >
              <i className="fas fa-eye"></i> View Chat
            </button>
            {method.images && (
              <button
                className="btn btn-info btn-sm ms-2"
                onClick={() => handleViewProof(method)}
              >
                <i className="fas fa-file-image me-1"></i> View Proof
              </button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'STATUS',
      cell: ({ row }) => {
        const method = row.original;
        const isOnHold = method.status === TRANSACTION_STATUS_ON_HOLD &&
          moment().diff(moment(method.updated_time * 1000), 'hours') >= 48;
        return isOnHold ? (
          <span
            className="text-danger cursor-pointer badge badge-phoenix badge-phoenix-danger"
            onClick={() => onChangeOnHold(method)}
          >
            Change to On Hold
          </span>
        ) : (
          <span
            className={`badge badge-phoenix fs-10 badge-phoenix-${statusesColor[method.status]
              } d-inline-flex align-items-center`}
          >
            {statuses[method.status]}
          </span>
        );
      },
    },
    {
      accessorKey: 'seller',
      header: 'SELLER',
      cell: ({ row }) => {
        const method = row.original;
        return (
          <div className="mb-0">
            <strong>{method.seller_info.seller_name}</strong>
            <br />
            <span>{method.seller_id.substring(0, 8)}</span>
            <br />
            <span className="text-uppercase">{method.seller_rank}</span>
            <br />
          </div>
        );
      },
    },
    {
      accessorKey: 'buyer',
      header: 'BUYER',
      cell: ({ row }) => {
        const method = row.original;
        return (
          <div className="mb-0">
            <strong>{method.buyer_info.buyer_name}</strong>
            <br />
            <span>{method.buyer_id.substring(0, 8)}</span>
            <br />
            <span>{method.buyer_info.buyer_email}</span>
            <br />
          </div>
        );
      },
    },
    {
      accessorKey: 'payment_method',
      header: 'PAYMENT METHOD',
      cell: ({ row }) => {
        const method = row.original;
        return <strong className="text-primary">{method?.payment_method?.name || ''}</strong>;
      },
    },
    {
      accessorKey: 'price',
      header: 'PRICE',
      cell: ({ row }) => {
        const method = row.original;
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: convertCurrency(method.price, true, settings?.data?.sgd),
            }}
          />
        );
      },
    },
    {
      accessorKey: 'fee',
      header: 'FEE',
      cell: ({ row }) => {
        const method = row.original;
        return <strong className="text-danger">{method.fee_charge_seller}%</strong>;
      },
    },
    {
      accessorKey: 'total',
      header: 'TOTAL',
      cell: ({ row }) => {
        const method = row.original;
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: convertCurrency(
                method.price - (method.price * method.fee_charge_seller) / 100,
                true,
                settings?.data?.sgd,
              ),
            }}
          />
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize,
        pageIndex: initialPage - 1,
      },
    },
    state: {
      pagination: {
        pageSize,
        pageIndex: initialPage - 1,
      },
    },
  });

  // Handle URL parameters on load
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const page = parseInt(params.page) || 1;
    const size = parseInt(params.pageSize) || pageSize;

    // Set initial page and page size from URL
    table.setPageIndex(page - 1);
    table.setPageSize(size);
  }, []);

  // Update URL when page changes
  const handlePageChange = (pageIndex) => {
    const newPage = pageIndex + 1;
    table.setPageIndex(pageIndex);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', newPage);
    urlParams.set('pageSize', table.getState().pagination.pageSize);
    window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());

    // Notify parent component of page change
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  // Update table state when pageSize changes
  useEffect(() => {
    table.setPageSize(pageSize);
    table.setPageIndex(0); // Reset to first page when page size changes
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('pageSize', pageSize);
    urlParams.set('page', '1');
    window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());
  }, [pageSize]);

  // Generate page numbers
  const getPageNumbers = () => {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const totalPages = table.getPageCount();
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <>
      <div className="table-responsive scrollbar ms-n1 ps-1">
        <table className="table table-sm fs-9 mb-0">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="sort align-middle text-center"
                    style={{ width: header.id === 'order' ? '30%' : '10%', minWidth: '100px' }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="list" id="members-table-body">
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="hover-actions-trigger btn-reveal-trigger position-static">
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="align-middle">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
          <div className="col-auto d-flex">
            <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
              Page {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </p>
          </div>
          <div className="col-auto d-flex">
            <button
              className="btn btn-link"
              onClick={() => handlePageChange(0)}
              disabled={!table.getCanPreviousPage()}
            >
              First
            </button>
            <button
              className="btn btn-link"
              onClick={() => handlePageChange(table.getState().pagination.pageIndex - 1)}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            <ul className="pagination mb-0">
              {getPageNumbers().map((pageNumber, index) => (
                <li key={index} className={`page-item ${pageNumber === '...' ? 'disabled' : ''} ${pageNumber === table.getState().pagination.pageIndex + 1 ? 'active' : ''}`}>
                  {pageNumber === '...' ? (
                    <span className="page-link">...</span>
                  ) : (
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber - 1)}
                    >
                      {pageNumber}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <button
              className="btn btn-link"
              onClick={() => handlePageChange(table.getState().pagination.pageIndex + 1)}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
            <button
              className="btn btn-link"
              onClick={() => handlePageChange(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              Last
            </button>
          </div>
        </div>
      </div>

      {/* Proof Modal */}
      {showProofModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="scrollingLong"
            tabIndex="-1"
            aria-labelledby="scrollingLongModalLabel"
            aria-hidden="true"
            style={{ display: 'block' }}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                handleCloseProofModal();
              }
            }}
          >
            {selectedOrder && (
              <div className="modal-dialog" style={{ maxWidth: '70%' }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="scrollingLongModalLabel">
                      Order ID: {selectedOrder._id}
                    </h5>
                    <button className="btn p-1" type="button" onClick={handleCloseProofModal} aria-label="Close">
                      <span className="fas fa-times fs-9"></span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ height: 'calc(100vh - 200px)' }}>
                    {selectedOrder.images && (
                      <div className="h-100">
                        {selectedOrder.images.map((image, index) => (
                          <video
                            key={index}
                            controls
                            autoPlay={true}
                            poster={image}
                            style={{
                              width: '100%',
                              maxHeight: '80vh',
                              marginBottom: '20px',
                            }}
                          >
                            <source src={image} type="video/mp4" />
                          </video>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-outline-primary" type="button" onClick={handleCloseProofModal}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* Chat Modal */}
      {showChatModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="chatModal"
            tabIndex="-1"
            aria-labelledby="chatModalLabel"
            aria-hidden="true"
            style={{ display: 'block' }}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                handleCloseChatModal();
              }
            }}
          >
            {selectedOrder && (
              <div className="modal-dialog" style={{ maxWidth: '90%', height: '90vh' }}>
                <div className="modal-content" style={{ height: '100%' }}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="chatModalLabel">
                      Order ID: {selectedOrder._id}
                    </h5>
                    <button className="btn p-1" type="button" onClick={handleCloseChatModal} aria-label="Close">
                      <span className="fas fa-times fs-9"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <iframe src={`/modal/marketplace/chat/${selectedOrder._id}`} width={'100%'} height={'100%'} />
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-outline-primary" type="button" onClick={handleCloseChatModal}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

TransactionsTable.propTypes = {
  data: PropTypes.array.isRequired,
  settings: PropTypes.object,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default TransactionsTable;
