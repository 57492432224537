import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { getReviewLists, unapproveReview, approveReview, deleteReview } from '../../../actions/review';
import { getSellerLists } from '../../../actions/seller';

import { formatDateTime } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: 10,
      },
      reviewList: [],
      total_items: 0,
      review: {},
      seller_id: null,
      sellers: [],
      isLoaded: false,
      loading: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.paginationHandler = this.paginationHandler.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deleteReview(id);
      const reviewList = this.state.reviewList.filter((el) => el._id !== id);
      this.setState({ reviewList });
    }
    return false;
  };
  filterReview = (seller_id) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          user_id: seller_id,
        },
      },
      () => {
        this.paginationHandler({
          selected: 0,
        });
      },
    );
  };
  handleDisable = (values, status) => {
    const confirm = window.confirm(`Are you sure ${!status ? 'active' : 'deactive'} this review?`);
    if (confirm) {
      const updateReview = values;
      updateReview.is_disable = status;
      const reviewList = this.state.reviewList;
      reviewList.forEach((el) => {
        if (el.id == values.id) {
          el['is_disable'] = status;
        }
      });
      this.setState({
        ...this.state,
        reviewList,
      });
      if (status) {
        this.props.unapproveReview(updateReview.id);
      } else {
        this.props.approveReview(updateReview.id);
      }
    }
    return false;
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.setState({ loading: true });
      this.props.getReviewLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
        isLoaded: true,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
      });
    }
  };
  componentDidMount() {
    this.props.getSellerLists({
      field_name: 'kyc_status',
      field_value: 2,
      page: 1,
      page_size: 20,
    });
  }
  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');

    // Check if the element exists and if Choices has not been initialized
    if (element && !window.choices) {
      window.choices = new window.Choices(element, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Seller',
        searchPlaceholderValue: 'Search Seller...',
        choices: [],
      });

      element.addEventListener(
        'search',
        function (event) {
          that.onInput(event.detail.value);
        },
        false,
      );
    } else if (element && window.choices) {
      // If Choices is already initialized, clear choices
      window.choices.clearChoices();
    }
  }
  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };
  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reviews !== this.props.reviews) {
      this.setState({
        reviewList: nextProps.reviews.reviews.data.reviews,
        total_items: nextProps.reviews.reviews.data.total_reviews,
        loading: false,
      });
    }
    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }

  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    page = page > 0 ? page - 1 : 0;
    const user_id = query.user_id;
    const newState = {
      ...this.state,
    };
    if (user_id) {
      newState.filter.user_id = user_id;
    }

    this.setState(
      {
        ...this.state,
        ...newState,
      },
      () => {
        this.paginationHandler({ selected: page });
      },
    );
  }

  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const pathname = window.location.pathname;
    const arg = {
      page: page.selected + 1,
    };
    if (this.state.filter.user_id) {
      arg.user_id = this.state.filter.user_id;
    }
    const params = new URLSearchParams(arg);
    const url = `${pathname}?${params}`;

    // Update the filter state and fetch new reviews
    this.setState(
      {
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      },
      () => {
        this.props.getReviewLists(this.state.filter);
      },
    );

    window.history.pushState(null, '', url); // Update the URL without reloading
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Marketplace</a>
            </li>
            <li className="breadcrumb-item active">Reviews</li>
          </ol>
        </nav>
        {/* <h2 className="text-bold text-body-emphasis mb-5">Reviews</h2> */}
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <select
                  id="seller"
                  className="form-select mb-3"
                  onChange={(e) => this.filterReview(e.target.value)}
                ></select>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="reviews/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add review
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="created_date"
                      style={{ width: '15%', minWidth: '100px' }}
                    >
                      CREATED DATE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="seller"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      SELLER
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="buyer"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      BUYER
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="rate"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      RATE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="comment"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      REVIEW
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      STATUS
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.loading ? (
                    <tr>
                      <td colSpan={8} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : this.state.reviewList.length > 0 ? (
                    this.state.reviewList.map((review) => (
                      <tr key={review._id} className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          {formatDateTime(review?.created_time * 1000)}
                        </td>
                        <td className="seller align-middle white-space-nowrap text-center">
                          <strong className="fw-bold text-body-emphasis">{review?.seller_info?.user_name}</strong>
                          <br />
                          {review?.seller_id.substring(0, 8)}
                        </td>
                        <td className="buyer align-middle white-space-nowrap text-center">
                          <strong className="fw-bold text-body-emphasis">{review?.buyer_info?.user_name}</strong>
                          <br />
                          {review?.buyer_id.substring(0, 8)}
                        </td>
                        <td className="mobile_number align-middle white-space-nowrap text-center">
                          {review?.rate}/5 <span className="fas fa-star text-warning"></span>
                        </td>
                        <td className="joined align-middle">{review?.comment}</td>
                        <td className="mobile_number align-middle white-space-nowrap text-center">
                          <span
                            className={`badge badge-phoenix fs-10 badge-phoenix-${
                              review?.is_disable ? 'danger' : 'success'
                            } d-inline-flex align-items-center`}
                          >
                            {!review?.is_disable ? 'Active' : 'Disabled'}
                          </span>
                        </td>
                        <td className="joined align-middle white-space-nowrap text-body-tertiary text-end">
                          <div className="btn-reveal-trigger position-static">
                            <button
                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <span className="fas fa-ellipsis-h fs-10"></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-2">
                              <a className="dropdown-item text-info" href={`reviews/edit/${review._id}`}>
                                <i className="fa-regular fa-pen-to-square me-1"></i>Edit
                              </a>
                              {review?.is_disable && (
                                <a
                                  className="dropdown-item text-success"
                                  href="#!"
                                  onClick={() => this.handleDisable(review, false)}
                                >
                                  Active
                                </a>
                              )}
                              {!review?.is_disable && (
                                <a
                                  className="dropdown-item text-danger"
                                  href="#!"
                                  onClick={() => this.handleDisable(review, true)}
                                >
                                  <i className="fa-regular fa-circle-xmark me-1"></i>Disable
                                </a>
                              )}
                              <a
                                className="dropdown-item text-danger"
                                href="#!"
                                onClick={() => this.handleDelete(review._id)}
                              >
                                <i className="fa-regular fa-trash-can me-1"></i>Remove
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div className="d-flex justify-content-center mt-3">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / 10)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Reviews.propTypes = {
  auth: PropTypes.object.isRequired,
  reviews: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getReviewLists: PropTypes.func.isRequired,
  unapproveReview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  reviews: state.reviews,
  errors: state.errors,
  sellers: state.sellers,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  getReviewLists,
  unapproveReview,
  approveReview,
  deleteReview,
  getSellerLists,
})(withRouter(Reviews));
