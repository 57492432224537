import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Countdown from 'react-countdown';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { withRouter } from '../../../withRouter';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';
import config from '../../../config';
import { formatDateTime, convertCurrency, imgModal, pasteIntoInput, escapeHTML } from '../../../utils/helpers';
import { sendNotification } from '../../../utils/notificationHelper';
import {
  TRANSACTION_STATUS_PARTIAL_REFUND,
  TRANSACTION_STATUS_BUYER_NOT_RECEIVE,
  TRANSACTION_STATUS_ON_HOLD,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_STATUS_BUYER_RECEIVE,
  TRANSACTION_STATUS_REFUND,
} from '../../../utils/constants';
import { getTransactionsDetail, updateTransaction } from '../../../actions/transaction';
import {
  getSupportReplies,
  createResolve,
  replySupport,
  updateSupport,
  getResolveDetail,
  getSupportDetail,
} from '../../../actions/ticket';
import ChatModal from '../../marketplace/transactions/ChatModal';

const OrderStatuses = {
  [TRANSACTION_STATUS_REFUND]: 'Canceled',
  [TRANSACTION_STATUS_ON_HOLD]: 'Delivered',
  [TRANSACTION_STATUS_BUYER_NOT_RECEIVE]: 'Suspended',
  [TRANSACTION_STATUS_BUYER_RECEIVE]: 'On Hold',
  [TRANSACTION_STATUS_PARTIAL_REFUND]: 'Partial Refund',
};
const RenderOrderStatuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Awaiting Delivery',
  'Transaction Completed',
  'Canceled',
  'Fail',
  'Delivered',
  'Suspended',
  'Transaction Completed',
  'Transaction Completed',
];

const requiredFields = ['refund', 'disbursement'];

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      lastReply: {},
      inputValue: '',
      imageUrl: '',
      replies: [],
      transaction: {},
      dispute: {},
      loading: false,
      order: {},
      tinymceInitialized: false,
    };
    this.editorRef = React.createRef();
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handleUpdatePriority = this.handleUpdatePriority.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateOrderStatus = this.handleUpdateOrderStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }
  uploadImage = async (file) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/support/`);
    body.append('type_upload', 'users');
    body.append('name', 'admin_chat');
    const me = this;

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      // .then((json) => me.setState({ imageUrl: json?.data?.img_urls[0] }))
      .then((json) => me.setState({ imageUrl: json?.data?.img_urls[0] }))
      .catch((err) => console.log(err));
  };
  handleImageUpload = () => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];
    const input = document.getElementById('supportChatPhotos');

    const file = input.files[0];
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.uploadImage(file);
      };

      reader.readAsArrayBuffer(file);
    }
  };
  handleInput = (e) => {
    e.preventDefault();
    e.target.style.height = 'auto';
    let height = e.target.scrollHeight < 300 ? e.target.scrollHeight : 300;
    e.target.style.height = height + 'px';
  };
  handleKeyUp = (e) => {
    if (e.keyCode == 13 && (e.shiftKey || e.altKey)) {
      if (e.type == 'keypress') {
        pasteIntoInput(this, '\n');
      }
    } else if (e.keyCode === 13) {
      const message = e.target.value;
      const validateMessage = (message || '').replaceAll('\n', '');
      this.setState({
        inputValue: validateMessage,
      });
      this.handleSubmit();
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const content = this.editorRef.current?.getContent();
    if (!content?.trim()) {
      createNotification('error', 'Please enter a message');
      return;
    }
    this.props.replySupport({
      subject: this.state.inputValue,
      replied_to: this.props.router.params.id,
      description: content,
      attachments: this.state.imageUrl,
      status: 'Open',
      priority: 'Low',
    });
    
    const replies = this.state.replies?.data || [];
    replies.push({
      subject: this.state.inputValue,
      replied_to: this.props.router.params.id,
      description: content,
      attachments: this.state.imageUrl,
      status: 'Open',
      priority: 'Low',
      created_time: new Date().getTime() / 1000,
    });
    
    if (this.editorRef.current) {
      this.editorRef.current.setContent('');
    }
    
    this.setState({
      inputValue: '',
      imageUrl: '',
      replies: {
        data: replies,
      },
      timer: new Date().getTime() / 1000,
    });
  };

  handleUpdateOrderStatus = async (e) => {
    await this.handleUpdateStatus('Close', e.target.value);
    await this.props.updateTransaction(this.state.transaction?.data?.tx_id, {
      status: e.target.value * 1,
    });
    await this.props.getTransactionsDetail(this.state.transaction?.data?._id);
    if (e.target.value * 1 !== TRANSACTION_STATUS_PARTIAL_REFUND) {
      sendNotification(
        this.state.transaction?.data?.seller_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
      sendNotification(
        this.state.transaction?.data?.buyer_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
    }
  };
  handleOrderStatus = async (e) => {
    await this.props.updateTransaction(this.state.transaction?.data?.tx_id, {
      status: e.target.value * 1,
    });
    await this.props.getTransactionsDetail(this.state.transaction?.data?._id);
    if (e.target.value * 1 !== TRANSACTION_STATUS_PARTIAL_REFUND) {
      sendNotification(
        this.state.transaction?.data?.seller_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
      sendNotification(
        this.state.transaction?.data?.buyer_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
    }
  };
  handleUpdateStatus = async (status, order_status) => {
    const newItem = {
      priority: this.props.tickets.support.priority,
      status,
    };
    if (order_status && status == 'Close') {
      newItem['object_status'] = order_status;
    }
    await this.props.updateSupport(this.props.tickets.support._id, newItem);
    await this.props.getSupportDetail(this.props.tickets.support._id);
  };
  handleUpdatePriority = (e) => {
    const newItem = {
      ...this.props.tickets.support,
      priority: e.target.value,
    };
    this.props.updateSupport(newItem._id, newItem);
    this.props.getSupportDetail(newItem._id);
  };
  handleTransactionStatus = (status) => {
    let stepActive = 2;
    if (status === TRANSACTION_STATUS_ON_HOLD) stepActive = 3;
    else if (
      status === TRANSACTION_STATUS_COMPLETED ||
      status === TRANSACTION_STATUS_BUYER_RECEIVE ||
      status === TRANSACTION_STATUS_PARTIAL_REFUND
    ) {
      stepActive = 4;
    }

    const stepTitle =
      status == TRANSACTION_STATUS_REFUND
        ? 'Canceled'
        : status == TRANSACTION_STATUS_BUYER_NOT_RECEIVE
          ? 'Suspended'
          : 'Delivered';
    let steps = ['', 'Order Created', 'Awaiting Delivery', stepTitle, 'Transaction Completed'];

    return steps[stepActive];
  };
  setOrder(order) {
    this.setState({
      order,
    });
  }
  updateState(name, value) {
    this.setState({
      dispute: {
        ...this.state.dispute,
        [name]: value,
      },
    });
  }
  handleOk = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.dispute, requiredFields);
      } catch (err) {
        this.setState({ loading: false });
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      const values = this.state.dispute;
      values.ticketid = this.props.tickets?.support?._id;

      if (values.refund * 1 + values.disbursement * 1 > this.state.transaction?.data?.price) {
        createNotification('error', 'The sum of Refund and disbursement has to be equal to Total');
        this.setState({ loading: false });
      } else {
        this.props.createResolve(values);
        this.setState({
          dispute: {},
          loading: false,
        });
        createNotification('success', lang('success.update'));
      }
    }
  };
  initializeTinyMCE = () => {
    if (window.tinymce.get('chatText')) {
      window.tinymce.remove('chatText');
    }

    const editorConfig = {
      selector: '#chatText',
      height: '500px',
      menubar: false,
      statusbar: false,
      plugins: 'link,image,lists,table,media',
      theme_advanced_toolbar_align: 'center',
      relative_urls: false,
      convert_urls: false,
      toolbar: [
        { name: 'history', items: ['undo', 'redo'] },
        {
          name: 'formatting',
          items: ['bold', 'italic', 'underline', 'strikethrough', 'forecolor'],
        },
        {
          name: 'heading',
          items: ['h1', 'h2', 'h3'],
        },
        {
          name: 'alignment',
          items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
        },
        { name: 'list', items: ['numlist', 'bullist'] },
        { name: 'link', items: ['link'] },
      ],
      automatic_uploads: true,
      file_picker_types: 'image',
      images_upload_handler: async function (blobInfo, success, failure) {
        const file = blobInfo.blob();
        const fileTypes = ['jpg', 'jpeg', 'png', 'gif'];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        const extension = file.name.split('.').pop().toLowerCase();
        const body = new FormData();
        if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
          body.append('file', file);
          body.append('path', `/support/`);
          body.append('type_upload', 'users');
          body.append('name', 'admin_chat');

          const res = await fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
            method: 'POST',
            body,
            headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
          });
          if (res.status === 200) {
            const data = await res.json();
            return data.data?.img_urls[0];
          } else {
            alert('Upload failed: ');
          }
        }
      },
      setup: (editor) => {
        editor.on('init', () => {
          this.editorRef.current = editor;
          this.setState({ tinymceInitialized: true });
        });
      }
    };

    window.tinymce.init(editorConfig);
  }
  
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.getSupportDetail(this.props.router.params.id);
      this.props.getSupportReplies(this.props.router.params.id);
      this.initializeTimer();
      
      // Initialize TinyMCE after a short delay to ensure DOM is ready
      setTimeout(() => {
        this.initializeTinyMCE();
      }, 500);
    }
  }
  initializeTimer = () => {
    const lastUpdated =
      this.props.tickets.replies?.data?.length > 0
        ? this.props.tickets.replies?.data[(this.props.tickets.replies?.data || []).length - 1]
        : this.props.tickets.support;

    const findLastResponder =
      this.props.tickets.replies?.data?.filter(
        (el) =>
          el.reply_type === 'normal' &&
          (el.replied_by === this.state.transaction?.data?.buyer_id ||
            el.replied_by === this.state.transaction?.data?.seller_id),
      ) || [];
    const lastReplyItem =
      findLastResponder?.length > 0 ? findLastResponder[findLastResponder.length - 1] : this.props.tickets.support;

    // Set timer based on ticket type
    const timer = this.props.tickets.support?.ticket_type === 'Dispute'
      ? this.props.tickets.support?.created_time
      : lastUpdated?.created_time;

    this.setState({
      timer,
      lastReply: lastReplyItem,
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.router.params.id != nextProps.router.params.id) {
      this.props.getSupportDetail(nextProps.router.params.id);
      this.props.getSupportReplies(nextProps.router.params.id);
      
      // Reinitialize TinyMCE when navigating to a new ticket
      setTimeout(() => {
        this.initializeTinyMCE();
      }, 500);
    }
    if (nextProps.tickets.support !== this.props.tickets.support) {
      if (nextProps.tickets.support?.object_id && nextProps.tickets.support?.ticket_type == 'Dispute') {
        this.props.getTransactionsDetail(nextProps.tickets.support?.object_id);
        if (Object.keys(nextProps.tickets.resolve).length == 0)
          this.props.getResolveDetail(nextProps.tickets.support?._id);
      }
      this.initializeTimer();
    }
    if (nextProps.tickets.replies !== this.props.tickets.replies) {
      this.setState({
        replies: nextProps.tickets.replies,
      });
      this.initializeTimer();
    }
    if (nextProps.transactions !== this.props.transactions) {
      this.setState({
        transaction: nextProps.transactions.transaction,
      });
    }
  }
  componentWillUnmount() {
    if (this.editorRef.current) {
      this.editorRef.current.remove();
    }
    if (window.tinymce && window.tinymce.get('chatText')) {
      window.tinymce.remove('chatText');
    }
  }
  render() {
    return (
      <div class="container">
        <div class="row mb-3">
          <div className="row align-items-center justify-content-between g-3 mb-2">
            <div className="col col-auto">
              <Link
                to={this.props.tickets.support?.ticket_type === 'Dispute' ? '/admin/support/disputes' : '/admin/support/tickets'}
                className="btn btn-link text-body me-4 px-0"
              >
                <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
              </Link>
            </div>
          </div>
          <div class={`col-md-6 p-2`}>
            <div class="card shadow-none border mb-3 p-4">
              <span>
                <h5>
                  {this.props.tickets.support?.ticket_type == 'Dispute' ? 'Dispute ID:' : 'Ticket ID:'}{' '}
                  {this.props.tickets.support?._id}
                </h5>
              </span>
              <hr />
              <div class="row">
                <div class="col">
                  <h6>Created By:</h6>
                  <div class="pb-2">
                    <span>
                      {this.props.tickets.support?.user_info?.user_name || this.props.tickets.support?.requested_by}
                    </span>
                    <span className="pl-1">
                      ({this.props.tickets.support?.requested_by || this.props.tickets.support?.replied_by})
                    </span>
                  </div>
                  {this.state.transaction?.data && (
                    <div class="pb-2">
                      <h6>Transaction ID:</h6>
                      <span>{this.state.transaction?.data?.merchant_trade_no}</span>
                    </div>
                  )}
                  <div class="pb-2">
                    <h6>
                      {this.props.tickets.support?.ticket_type == 'Dispute'
                        ? 'Dispute Create Date:'
                        : 'Ticket Create Date:'}
                    </h6>
                    <span>{formatDateTime(this.props.tickets.support?.created_time * 1000)}</span>
                  </div>

                  <div class="pb-2">
                    <h6>
                      {this.props.tickets.support?.ticket_type == 'Dispute' ? 'Dispute Status:' : 'Ticket Status:'}
                    </h6>
                    <select
                      class="form-select"
                      style={{ width: '150px' }}
                      value={this.props.tickets.support?.status || ''}
                      onChange={(e) => this.handleUpdateStatus(e.target.value)}
                    >
                      <option value="">Select status</option>
                      {['Close', 'Open'].map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.transaction?.data &&
                    this.props.tickets.support?.ticket_type === 'Dispute' &&
                    this.props.tickets.support?.object_id &&
                    this.state.transaction?.data?._id === this.props.tickets.support?.object_id && (
                      <div class="pb-2">
                        <h6>Order Status:</h6>
                        <select
                          class="form-select"
                          data-choices="data-choices"
                          data-options='{"removeItemButton":false,"placeholder":true}'
                          style={{ width: '150px' }}
                          onChange={this.handleUpdateOrderStatus}
                        >
                          <option value="">Select status</option>
                          {Object.keys(OrderStatuses).map((el) => (
                            <option value={el} selected={this.props.tickets.support?.object_status == el}>
                              {OrderStatuses[el]}
                            </option>
                          ))}
                        </select>
                        {/* <h6>Order Status:</h6>
                        <select
                          class="form-select1"
                          data-choices="data-choices"
                          data-options='{"removeItemButton":false,"placeholder":true}'
                          style={{ width: '100px' }}
                          onChange={this.handleOrderStatus}
                        >
                          <option value="">Select status</option>
                          {Object.keys(OrderStatuses).map((el) => (
                            <option value={el} selected={this.props.tickets.support?.object_status == el}>
                              {OrderStatuses[el]}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    )}
                  {this.props.tickets.support?.status == 'Close' && (
                    <>
                      <div class="pb-2">
                        <h6>Dispute Closed Date:</h6>
                        <span>{formatDateTime(this.props.tickets.support?.updated_time * 1000)}</span>
                      </div>
                      {this.props.tickets.support?.object_status && (
                        <div class="pb-2">
                          <h6>Final decision maker:</h6>
                          <span>
                            {this.props.tickets.support?.updated_by == '55489c06-557a-40f0-ad0e-e910bf8b017c'
                              ? 'The PlaySwap Final Resolution Committee'
                              : this.props.tickets.support?.closer_info.user_name}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div>
                {this.state.timer > 0 && this.props.tickets.support?.status !== 'Close' && (
                  <>
                    <p>
                      {this.props.tickets.support?.ticket_type === 'Dispute' ? (
                        <>
                          <span>The Dispute will be closed after </span>
                          <span className="text-danger">
                            <Countdown date={this.state.timer * 1000 + 48 * 3600000} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            The Ticket will be automatically closed after{' '}
                            <span className="text-danger">
                              <Countdown date={this.state.timer * 1000 + 48 * 3600000} />
                            </span>{' '}
                            hours if there is no response.{' '}
                          </span>
                        </>
                      )}
                    </p>
                    <hr />
                  </>
                )}

                {this.props.tickets.resolve?.data &&
                  this.props.tickets.resolve?.data?.ticketid === this.props.tickets.support?._id ? (
                  <div className="row">
                    <div className="col">
                      <div className="pb-2 py-1 text-center">
                        <h5>Dispute Outcome</h5>
                      </div>

                      <div className="pb-2">
                        <h6>Refund to the Buyer:</h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(
                              this.props.tickets.resolve?.data?.refund,
                              true,
                              this.props.settings?.data?.sgd,
                            ),
                          }}
                        />
                      </div>

                      <div className="pb-2">
                        <h6>Disbursement to the Seller:</h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(
                              this.props.tickets.resolve?.data?.disbursement,
                              true,
                              this.props.settings?.data?.sgd,
                            ),
                          }}
                        />
                      </div>

                      <div className="pb-2">
                        <h6>Decision time:</h6>
                        <div>{formatDateTime((this.props.tickets.resolve?.data?.created_time || 1) * 1000)}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div class="col-6 col-md-6 p-2">
            <div class="card shadow-none border mb-3 p-4">
              {this.props.tickets.support?.ticket_type === 'Dispute' && (
                <>
                  <h5 className="d-flex align-items-center">
                    Order ID: {this.state.transaction?.data?._id}
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      onClick={() => {
                        const orderId = this.state.transaction?.data?._id;
                        if (orderId) {
                          navigator.clipboard.writeText(orderId);
                          // Hiển thị thông báo
                          this.setState({ copyMessage: true });
                          setTimeout(() => {
                            this.setState({ copyMessage: false });
                          }, 2000);
                        }
                      }}
                    >
                      Copy
                    </button>

                    {this.state.copyMessage && (
                      <div
                        style={{
                          position: 'fixed',
                          bottom: '20px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          backgroundColor: 'rgba(0,0,0,0.7)',
                          color: 'white',
                          padding: '10px 20px',
                          borderRadius: '50px',
                          zIndex: 1000
                        }}
                      >
                        ✅ Copied Successfully
                      </div>
                    )}
                  </h5>

                  <hr />
                  <div className="row">
                    <div className="col">
                      <div class="pb-2">
                        <h6> Order Title: </h6>
                        <span className="d-flex flex-column">
                          <span>{this.state.transaction?.data?.item_name}</span>
                          <strong>x {this.state.transaction?.data?.amount}</strong>
                          <div className="d-flex justify-content-start">
                            <button
                              className="btn btn-info btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#chatModal"
                              onClick={() => this.setOrder(this.state.transaction?.data)}
                            >
                              <i className="fas fa-eye"></i> View Chat
                            </button>
                            {this.state.transaction?.data?.images && (
                              <button
                                className="btn btn-info btn-sm ms-2"
                                data-bs-toggle="modal"
                                data-bs-target="#scrollingLong"
                                onClick={() => this.setOrder(this.state.transaction?.data)}
                              >
                                <i class="fas fa-file-image me-1"></i> View Proof
                              </button>
                            )}
                          </div>
                        </span>
                      </div>
                      {/* <div class="pb-2">
                        <h6>Order ID: </h6>
                        <span>{this.state.transaction?.data?._id}</span>
                      </div> */}
                      <div class="pb-2">
                        <h6>Buyer Name: </h6>
                        <span>{this.state.transaction?.data?.buyer_info?.user_name}</span>
                      </div>
                      <div class="pb-2">
                        <h6>Seller Name: </h6>
                        <span>{this.state.transaction?.data?.seller_info?.user_name}</span>
                      </div>
                      <div class="pb-2">
                        <h6>Order Status: </h6>
                        <span>{RenderOrderStatuses[this.state.transaction?.data?.status]}</span>
                      </div>
                      <div class="pb-2">
                        <h6>Order Purchase Date: </h6>
                        <span className="text-danger">
                          {formatDateTime((this.state.transaction?.data?.created_time || 1) * 1000)}
                        </span>
                      </div>
                      {/* <div class="pb-2">
                        <h6>Quantity: </h6>
                        <span>{this.state.transaction?.data?.amount}</span>
                      </div> */}
                      <div class="pb-2">
                        <h6>Amount: </h6>
                        <span class="text-danger">${this.state.transaction?.data?.price}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className="row">
                  {/* <div class="pb-2">
                    <h6>Reason: </h6>
                    <span>{this.props.tickets?.support?.subject}
                    </span>
                  </div> */}
                  <div class="border-bottom">
                    <h6>{this.props.tickets?.support?.subject}</h6>
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: escapeHTML(this.props.tickets?.support?.description),
                      }}
                    />
                    {this.props.tickets?.support?.attachments && (
                      <div>
                        {this.props.tickets?.support?.attachments && (
                          <img
                            aria-hidden="true"
                            onClick={() => imgModal(this.props.tickets?.support?.attachments)}
                            src={this.props.tickets?.support?.attachments}
                            width="50"
                            alt="image"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {this.props.transactions.transaction &&
                    this.props.tickets?.support?.ticket_type === 'Dispute' &&
                    this.props.tickets?.support?.object_id &&
                    this.props.transactions.transaction?.data?._id === this.props.tickets?.support?.object_id && (
                      <>
                        {(this.state.replies?.data?.filter((i) => i.reply_type === 'request') || []).length > 0 && (
                          <>
                            <h5 className="mt-3 text-center">Response Detail</h5>

                            {(this.state.replies?.data?.filter((i) => i.reply_type === 'request') || []).map((el) => {
                              return (
                                <>
                                  <div className="row">
                                    <div className="col">
                                      <div className="pb-2">
                                        <h6>
                                          {`${el.replied_by === this.props.transactions.transaction?.data?.seller_id
                                            ? 'The Seller'
                                            : 'The Buyer'
                                            } ${el.replied_by === this.props.transactions.transaction?.data?.seller_id
                                              ? 'wish to refund'
                                              : 'wants a refund of'
                                            }`}
                                          :{' '}
                                        </h6>
                                        <div
                                          className=""
                                          dangerouslySetInnerHTML={{
                                            __html: convertCurrency(el.subject, true, this.props.settings?.data?.sgd),
                                          }}
                                        />
                                      </div>
                                      <div className="pb-2">
                                        <h6>Return the account: </h6>
                                        <strong class="text-danger">{el.description}</strong>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                        {this.props.tickets?.resolve?.data &&
                          this.props.tickets?.resolve?.data?.ticketid === this.props.tickets?.support?._id ? (
                          <></>
                        ) : (
                          this.props.transactions.transaction?.data?.status === 10 && (
                            <div className="col-span-2 pt-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                aria-hidden="true"
                                data-bs-toggle="modal"
                                data-bs-target="#disputeModal"
                              >
                                <div>Dispute Outcome</div>
                              </button>
                            </div>
                          )
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-2">
            <div class="card shadow-none border mb-3 p-4">
              {/* <h4 className="pb-2">Conversation</h4> */}
              {this.state.replies?.data &&
                this.state.replies?.data
                  ?.filter((i) => i.reply_type !== 'request')
                  .map((item) => {
                    return (
                      <div className="py-3 px-4 bg-white dark:bg-gray-800 rounded-2xl shadow-md" key={item._id}>
                        {/* Header: Avatar + Tên + Thời gian */}
                        <div className="pb-1 row">
                          <div className="col d-flex align-items-center">
                            {/* Ảnh đại diện */}
                            <div className="avatar avatar-m">
                              <img
                                className="rounded-circle"
                                src={
                                  item?.user_info?.user_avatar ||
                                  'https://assets.playswap.gg/s3/users/55489c06-557a-40f0-ad0e-e910bf8b017c/Dech32M1733455972slDQVYIijlU'
                                }
                                alt="User Avatar"
                              />
                            </div>
                            {/* Tên người dùng */}
                            <h5 className="px-2 text-lg font-semibold text-gray-900 dark:text-white">
                              {item?.user_info?.user_name || 'Playswap Admin'}
                            </h5>
                          </div>
                          {/* Thời gian */}
                          <div className="text-sm text-gray-500 dark:text-white60">
                            {moment(new Date(item.created_time * 1000)).format('YYYY-MM-DD HH:mm:ss')}
                          </div>
                        </div>

                        {/* Nội dung tin nhắn */}
                        <div
                          className="p-3 bg-gray-100 dark:bg-gray-700 rounded-lg text-gray-800 dark:text-white text-[15px]"
                          dangerouslySetInnerHTML={{
                            __html: escapeHTML(item.description).replaceAll('\n', '<br/>'),
                          }}
                        />

                        {/* Ảnh đính kèm (nếu có) */}
                        {item.attachments && (
                          <div className="mt-3">
                            <img
                              className="rounded-lg shadow-md cursor-pointer"
                              aria-hidden="true"
                              onClick={() => imgModal(item.attachments)}
                              alt="Attachment"
                              src={item.attachments}
                              width="150"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
              <div>
                <textarea
                  id="chatText"
                  data-tinymce-custom='{"height":"15rem, "width": "100%","placeholder":"Reply..."}'
                  class="form-control outline-none border-0 flex-1 fs-9 px-0 tinymce-custom"
                  placeholder="Reply..."
                  // style={{ height: '30px' }}
                  // value={this.state.inputValue}
                  onKeyUp={(e) => {
                    this.handleKeyUp(e);
                    this.handleInput(e);
                  }}
                // onChange={(e) => {
                //   this.setState({
                //     inputValue: e.target.value,
                //   });
                // }}
                />
                {/* <div class="card-footer d-flex justify-content-center align-items-center gap-2 ps-3 pe-4 py-3">
                  <label
                    class="btn btn-link d-flex justify-content-center align-items-center text-body-quaternary fs-9 border-0"
                    htmlFor="supportChatPhotos"
                  >
                    <span class="fa-solid fa-image"></span>
                  </label>
                  <div class="d-flex align-items-center flex-1 gap-3 border border-translucent rounded px-4">
                    <textarea
                      id="chatText"
                      data-tinymce='{"height":"15rem, "width": "100%","placeholder":"Write a intro here..."}'
                      class="form-control outline-none border-0 flex-1 fs-9 px-0 tinymce"
                      placeholder="Aa"
                      // style={{ height: '30px' }}
                      value={this.state.inputValue}
                      onKeyUp={(e) => {
                        this.handleKeyUp(e);
                        this.handleInput(e);
                      }}
                      onChange={(e) => {
                        this.setState({
                          inputValue: e.target.value,
                        });
                      }}
                    />

                    <input
                      class="d-none "
                      type="file"
                      accept="image/*"
                      id="supportChatPhotos"
                      onChange={this.handleImageUpload}
                    />
                    <button class="btn p-0 border-0 send-btn" onClick={this.handleSubmit}>
                      <span class="fa-solid fa-paper-plane fs-9"></span>
                    </button>
                  </div>
                </div> */}
                {/* <div class="ps-3 pe-4">{this.state.imageUrl}</div> */}
              </div>
              <button
                style={{ width: 80, height: 30 }}
                class="btn btn-primary p-1 border-0 send-btn mt-2 ml-auto"
                onClick={this.handleSubmit}
                disabled={!this.state.tinymceInitialized}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div class="modal fade" id="disputeModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <form class="row g-3 needs-validation" novalidate="">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Dispute Outcome
                  </h5>
                  <button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span class="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingDisbursement"
                      required
                      placeholder="Input Disbursement to the Seller"
                      value={this.state.dispute?.disbursement}
                      onChange={(e) => this.updateState('disbursement', e.target.value)}
                    />
                    <label htmlFor="floatingDisbursement">Disbursement to the Seller</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingRefund"
                      required
                      placeholder="Input Refund Amount to the Buyer"
                      value={this.state.dispute?.refund}
                      onChange={(e) => this.updateState('refund', e.target.value)}
                    />
                    <label htmlFor="floatingRefund">Refund Amount to the Buyer</label>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary" type="button" disabled={this.state.loading} onClick={this.handleOk}>
                    Save
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      this.setState({
                        dispute: {},
                        loading: true,
                      })
                    }
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scrollingLong"
          tabIndex="-1"
          aria-labelledby="scrollingLongModalLabel"
          aria-hidden="true"
        >
          {this.state.order && (
            <div className="modal-dialog" style={{ maxWidth: '70%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="scrollingLongModalLabel">
                    {this.state.transaction?.data?.item_name}
                  </h6>
                  <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span className="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div className="modal-body">
                  {(this.state.order?.images || []).length > 0 &&
                    (this.state.order?.images || []).map((el, index) => (
                      <video
                        key={index}
                        controls
                        autoPlay={true}
                        poster={el}
                        style={{
                          width: '100%',
                          backgroundImage: `url(${el})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          maxHeight: '500px',
                        }}
                      >
                        <source src={el} type="video/mp4" />
                      </video>
                    ))}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal fade" id="chatModal" tabIndex="-1" aria-labelledby="chatModalLabel" aria-hidden="true">
          <ChatModal orderId={this.state.order?._id} />
        </div>
      </div>
    );
  }
}

SupportDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  getSupportReplies: PropTypes.func.isRequired,
  getTransactionsDetail: PropTypes.func.isRequired,
  createResolve: PropTypes.func.isRequired,
  replySupport: PropTypes.func.isRequired,
  updateSupport: PropTypes.func.isRequired,
  getResolveDetail: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  getSupportDetail: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  tickets: state.tickets,
  transactions: state.transactions,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getTransactionsDetail,
  getSupportReplies,
  createResolve,
  replySupport,
  updateSupport,
  getResolveDetail,
  updateTransaction,
  getSupportDetail,
})(withRouter(SupportDetail));
