import React, { Component } from 'react';
import { connect } from 'react-redux';
import MonthPicker from 'simple-react-month-picker';
import moment from 'moment';

import PropTypes from 'prop-types';
import GeneralReport from './General';
import TopSellers from './TopSellers';
import TopBuyers from './TopBuyers';
import Revenue from './Revenue';
import Wallets from './Wallets';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      selectedEndMonth: new Date().getMonth() + 1,
      selectedEndYear: new Date().getFullYear(),
    };
  }
  setSelected = (value) => {
    const startDate = new Date(value[0]);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endDate = new Date(value[1]);
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    this.setState({
      selectedMonth: startMonth,
      selectedYear: startYear,
      selectedEndMonth: endMonth,
      selectedEndYear: endYear,
    });
  };
  render() {
    return (
      <div className="pb-6">
        <div className="row align-items-center justify-content-between g-3 mb-6">
          <div className="col-12 col-md-auto">
            <h2 className="mb-0">Analytics</h2>
          </div>
          <div className="col-12 col-md-auto">
            <MonthPicker
              style={{ width: 300, margin: '50px auto' }}
              value={[moment().startOf('month').toDate(), moment().endOf('month').toDate()]}
              presets={[
                {
                  title: 'This month',
                  start: moment().startOf('month').toDate(),
                  end: moment().endOf('month').toDate(),
                },
                {
                  title: 'Last month',
                  start: moment().subtract(1, 'month').startOf('month').toDate(),
                  end: moment().endOf('month').toDate(),
                },
              ]}
              onChange={(d) => this.setSelected(d)}
              closeDelay={500}
            />
          </div>
        </div>
        <GeneralReport
          month={this.state.selectedMonth}
          year={this.state.selectedYear}
          endMonth={this.state.selectedEndMonth}
          endYear={this.state.selectedEndYear}
        />
        <div class="row pt-6 gy-7 gx-6">
          <div class="col-12">
            <Revenue year={this.state.selectedYear} />
          </div>
          <div class="col-12">
            <Wallets year={this.state.selectedYear} />
          </div>
        </div>
      </div>
    );
  }
}

Analytics.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps, {})(Analytics);
