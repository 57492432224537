import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { getUserLists, getAllUserLists, userUpdateData, blockUser, activeUser } from '../../../actions/user';
import { DEFAULT_PAGE_SIZE, USER_STATUS_ENUM, SOCIAL_TYPE } from '../../../utils/constants';
import { formatDateTime, createNotification, getUserId } from '../../../utils/helpers';
import SuspendModal from './Suspend';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        field_name: this.props.type ? 'type,status' : 'status',
        field_value: this.props.type ? `${this.props.type},1` : this.props.status,
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      userList: [],
      allUsers: [],
      total_items: 0,
      user: null,
      loading: true,
      csvData: [],
      loadingCsv: true,
      page_size: DEFAULT_PAGE_SIZE,
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  onChangePageSize = (value) => {
    this.setState({ loading: true, loadingCsv: true }, () => {
      this.paginationHandler({ selected: 0 }, value);
    });
  };

  getUsers = () => {
    // this.props.getAllUserLists({
    //   field_name: this.props.type ? 'type,status' : 'status',
    //   field_value: this.props.type ? `${this.props.type},1` : this.props.status,
    //   page: 1,
    //   page_size: 1000,
    // });
  };

  generateCSV = () => {
    const result = [['Username', 'Email']];
    this.state.userList.forEach((el) => {
      result.push([el.name, el.email]);
    });
    this.setState({ csvData: result });
  };

  handleDisable = async (values, status) => {
    const confirm = window.confirm(`Are you sure ${USER_STATUS_ENUM[status]} this user?`);
    if (confirm) {
      const updateUser = values;
      updateUser.status = status;
      const userList = this.state.userList;
      userList.forEach((el) => {
        if (el.id == values.id) {
          el['status'] = status;
        }
      });
      this.setState({
        ...this.state,
        userList,
      });
      if (status == 1) {
        await this.props.activeUser(updateUser.id);
      } else {
        await this.props.blockUser(updateUser.id, this.state.suspend_day * 1);
        document.querySelector('#modalClose').click();
      }
      this.props.getUserLists({
        ...this.state.filter,
      });
    }
    return false;
  };

  handleOk = (suspend_day) => {
    if (suspend_day >= 0) {
      this.setState(
        {
          suspend_day,
        },
        () => {
          this.handleDisable(this.state.user, 0);
        },
      );
    } else {
      createNotification('error', 'Suspend Day needs to more than 0.');
    }
  };

  paginationHandler = (page, page_size = DEFAULT_PAGE_SIZE) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getUserLists({
        ...this.state.filter,
        page: page.selected + 1,
        page_size,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
          page_size,
        },
        isLoaded: true,
        loading: false,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
        loading: false,
      });
    }
  };
  searchUser = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          field_name: this.props.type ? 'type,status,keyword' : 'status,keyword',
          field_value: this.props.type
            ? `${this.props.type},1,${keyword.toLowerCase()}`
            : `${this.props.status},${keyword.toLowerCase()}`,
          page: 1,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.users.allUsers !== this.props.users.allUsers) {
    //   this.setState(
    //     {
    //       ...this.state,
    //       allUsers: nextProps.users.allUsers.users,
    //       loadingCsv: false,
    //     },
    //     () => {
    //       this.generateCSV();
    //     },
    //   );
    // }
    if (nextProps.users.users !== this.props.users.users) {
      this.setState(
        {
          ...this.state,
          userList: nextProps.users.users.users,
          total_items: nextProps.users.users.total_items,
          loadingCsv: false,
          loading: false,
        },
        () => {
          this.getUsers();
          this.generateCSV();
        },
      );
    }
    if (this.props.type !== nextProps.type || nextProps.status !== this.props.status) {
      this.setState(
        {
          ...this.state,
          filter: {
            field_name: nextProps.type ? 'type,status' : 'status',
            field_value: nextProps.type ? `${nextProps.type},1` : nextProps.status,
            page: 1,
          },
        },
        () => {
          this.paginationHandler({ selected: 0 });
        },
      );
    }
  }

  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    let page_size = parseInt(query.page_size) || DEFAULT_PAGE_SIZE;
    page = page > 0 ? page - 1 : 0;
    this.paginationHandler({ selected: page }, page_size);
  }

  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const pathname = window.location.pathname;
    const arg = {
      page: page.selected + 1,
      page_size: this.state.filter.page_size,
    };
    const params = new URLSearchParams(arg);
    const url = `${pathname}?${params}`;
    window.location.href = url;
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </nav>

        <h4 className="m-3">Total: {this.state.total_items}</h4>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search by User ID or Email"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchUser(event.target.value)}
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex align-items-center">
                <div>
                  <select id="pageSize" className="form-select" onChange={(e) => this.onChangePageSize(e.target.value)}>
                    <option value={DEFAULT_PAGE_SIZE}>{DEFAULT_PAGE_SIZE}</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                </div>
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData} filename={'users.csv'}>
                    {this.state.loadingCsv ? 'Loading csv..' : 'Export'}
                  </CSVLink>
                </button>
                <a href="users/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add member
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="id"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      USER ID
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      EMAIL
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      USERNAME
                    </th>
                    {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                      <th
                        className="sort align-middle text-center"
                        scope="col"
                        data-sort="last_active"
                        style={{ width: '5%', minWidth: '150px' }}
                      >
                        LAST ACTIVE
                      </th>
                    )}
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      FULL NAME
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="account_social"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      LOGIN TYPE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="slug"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      SLUG
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '15%', minWidth: '100px' }}
                    >
                      IP ADDRESS
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="dob"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      DOB
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="contact"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      CONTACT METHOD
                    </th>

                    {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                      <th
                        className="sort align-middle text-center"
                        scope="col"
                        data-sort="mobile_number"
                        style={{ width: '5%', minWidth: '100px' }}
                      >
                        ROLE
                      </th>
                    )}

                    {['Blocked User'].indexOf(this.props.role) >= 0 && (
                      <>
                        <th
                          className="sort align-middle text-center"
                          scope="col"
                          data-sort="suspended_day"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          ACCOUNT LOCK DURATION
                        </th>
                        <th
                          className="sort align-middle text-center"
                          scope="col"
                          data-sort="last_active"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          DATE START
                        </th>
                        <th
                          className="sort align-middle text-center"
                          scope="col"
                          data-sort="last_active_end"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          DATE END
                        </th>
                      </>
                    )}
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="joined"
                      style={{ width: '19%', minWidth: '200px' }}
                    >
                      JOINED
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      style={{ width: '15%', minWidth: '200px' }}
                    ></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.userList || []).map((user) => {
                    const settings = JSON.parse(user.settings);
                    return (
                      <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                        <td class="fs-9 align-middle ps-0 py-3">
                          <div class="form-check mb-0 fs-8">
                            <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td class="id align-middle white-space-nowrap text-uppercase fw-semibold text-start">
                          {getUserId(user.id)}
                        </td>
                        <td class="email align-middle white-space-nowrap text-center">
                          <a class="fw-semibold" href={`mailto:${user.email}`}>
                            {user.email}
                          </a>
                        </td>
                        <td class="customer align-middle white-space-nowrap text-center">
                          <div class="d-flex align-items-center text-body text-hover-1000 ">
                            <div class="avatar avatar-m">
                              <img
                                class="rounded-circle"
                                src={
                                  user.avatar ||
                                  'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                                }
                                alt=""
                              />
                            </div>
                            <h6 class="username mb-0 ms-1 fw-semibold ">{user.name}</h6>
                            <br />
                            {user.rank && <span>({user.rank})</span>}
                          </div>
                        </td>
                        {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                          <td class="last_active align-middle white-space-nowrap text-body-tertiary text-center">
                            {user.last_seen ? moment(new Date(user.last_seen * 1000).getTime()).fromNow() : ''}
                          </td>
                        )}
                        <td class="slug align-middle white-space-nowrap text-center">
                          {settings?.real_name || `${settings?.first_name} ${settings?.last_name}`}
                        </td>
                        <td class="slug align-middle white-space-nowrap text-center">
                          {user.account_social ? 'GOOGLE' : 'EMAIL'}
                        </td>
                        <td class="slug align-middle white-space-nowrap text-center">{user.slug}</td>
                        <td className="mobile_number align-middle white-space-nowrap text-center">
                          {user.ip_address} <br />
                          {user.country && <span className="text-danger">({user.country})</span>}
                        </td>
                        <td class="slug align-middle white-space-nowrap text-center">{settings?.dob}</td>
                        <td class="slug align-middle white-space-nowrap text-center">
                          {settings?.contact_method
                            ? `${SOCIAL_TYPE[settings?.contact_method || 0]} (${settings?.contact_method_url || ''})`
                            : ''}
                        </td>

                        {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                          <td class="mobile_number align-middle white-space-nowrap text-center">{this.props.role}</td>
                        )}

                        {['Blocked User'].indexOf(this.props.role) >= 0 && (
                          <>
                            <td class="last_active align-middle white-space-nowrap text-body-tertiary text-center">
                              {user?.suspend_day || 'Forever'}
                            </td>
                            <td class="last_active align-middle white-space-nowrap text-body-tertiary text-center">
                              {formatDateTime(user.updated_at)}
                            </td>
                            <td class="last_active align-middle white-space-nowrap text-body-tertiary text-center">
                              {user?.suspend_day
                                ? formatDateTime(moment(new Date(user.updated_at)).add(user?.suspend_day || 0, 'days'))
                                : 'Forever'}
                            </td>
                          </>
                        )}
                        <td class="joined align-middle white-space-nowrap text-body-tertiary text-center">
                          {formatDateTime(user.created_at)}
                        </td>
                        <td class="joined align-middle white-space-nowrap text-body-tertiary text-start">
                          {user.status === 1 && (
                            <button
                              class="btn btn-primary me-2"
                              onClick={() => (window.location.href = `users/edit/${user.id}`)}
                            >
                              <i class="fa-solid fa-pen-to-square me-1"></i>Edit
                            </button>
                          )}
                          {user.status === 1 ? (
                            <button
                              class="btn btn-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#disputeModal"
                              onClick={() =>
                                this.setState({
                                  user,
                                })
                              }
                            >
                              <i class="fa-solid fa-ban me-1"></i> Suspend
                            </button>
                          ) : (
                            <button class="btn btn-success" onClick={() => this.handleDisable(user, 1)}>
                              <i class="fa-solid fa-circle-check me-1"></i>
                              {user.status === 0 ? 'Active' : 'Unblock'}
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9 mt-3">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
                  View {this.state.filter.page * DEFAULT_PAGE_SIZE} of {this.state.total_items}
                </p>
              </div>
              <div class="col-auto d-flex">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / this.state.filter.page_size)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>

        <SuspendModal handleOk={this.handleOk} />
      </>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getUserLists: PropTypes.func.isRequired,
  userUpdateData: PropTypes.func.isRequired,
  activeUser: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, { getUserLists, getAllUserLists, userUpdateData, blockUser, activeUser })(
  withRouter(Users),
);
