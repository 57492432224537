import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';

import { formValidation } from '../../validations/authValidate';
import { createNotification, pasteIntoInput } from '../../utils/helpers';
import lang from '../../langs';
import config from '../../config';
import {
  createGame,
  updateGame,
  getGameDetail,
  createGameProductType,
  removeGameProductType,
  createGameVariant,
  updateGameVariant,
} from '../../actions/games';
import { getGameTypeLists } from '../../actions/gameType';
import { getProductTypeLists } from '../../actions/productType';
import { withRouter } from '../../withRouter';

const requiredFields = ['name', 'slug', 'intro', 'description', 'category_tag', 'icon'];

const ExtraDescription = (props) => {
  const { type, defaultValue, onChange, name, title } = props;

  React.useEffect(() => {
    window.tinymce
      .createEditor(`${type.slug}_${name}`, {
        menubar: false,
        statusbar: false,
        plugins: 'link,image,lists,table,media',
        theme_advanced_toolbar_align: 'center',
        toolbar: [
          { name: 'history', items: ['undo', 'redo'] },
          {
            name: 'formatting',
            items: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          {
            name: 'heading',
            items: ['h1', 'h2', 'h3'],
          },
          {
            name: 'alignment',
            items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
          },
          { name: 'list', items: ['numlist', 'bullist'] },
          { name: 'link', items: ['link'] },
        ],
        setup: function (ed) {
          ed.on('Paste Change input Undo Redo', function (e) {
            onChange(type.slug, ed.getContent());
          });
        },
      })
      .render();
    setTimeout(() => {
      if (defaultValue) window.tinymce.get(`${type.slug}_${name}`).setContent(defaultValue);
    }, 200);
  }, []);

  return (
    <div className="mb-6">
      <h4 className="mb-3">
        {title} ({type.name})
      </h4>
      <textarea
        className="tinymce"
        name="content"
        id={`${type.slug}_${name}`}
        data-tinymce='{"height":"15rem","placeholder":"Write a description here..."}'
      ></textarea>
    </div>
  );
};

class FormGames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Game' : 'Create Game',
      game: {},
      productTypes: [],
      gameTypes: [],
      attributes: [],
      category_tag: null,
      product_types: [],
      image_url: '',
      collection_url: [],
      icon: null,
      collection: null,
      loading: false,
      extraDescriptions: {},
      extraIntroductions: {},
      extraMetaTitles: {},
      extraMetaDescriptions: {},
    };
    this.handleSave = this.handleSave.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateState(name, value) {
    let params = { [name]: value };
    if (name == 'name') {
      params['slug'] = slugify(value.toLowerCase());
    }
    this.setState({
      game: {
        ...this.state.game,
        ...params,
      },
    });
  }

  processData = (values) => {
    values.image_url = this.state.image_url;
    values.collection_url = this.state.collection_url;
    values.descriptions = this.state.extraDescriptions;
    values.intros = this.state.extraIntroductions;
    values.meta_descriptions = this.state.extraMetaDescriptions;
    values.meta_titles = this.state.extraMetaTitles;

    delete values.icon;
    delete values.collection;
    if (this.props.router.params.id) {
      this.props.updateGame(this.props.router.params.id, values);
      //process product types
      let oldProductTypes = _.uniq(this.state.game?.product_types.map((el) => el.product_type_id));
      // product_types that need to remove
      let removeItems = _.difference(oldProductTypes, this.state.product_types);
      // product_types that need to add
      let addItems = _.difference(this.state.product_types, oldProductTypes);
      removeItems.forEach((el) => {
        this.props.removeGameProductType({
          category_id: this.props.router.params.id,
          product_type_id: el,
        });
      });
      addItems.forEach((el) => {
        this.props.createGameProductType({
          category_id: this.props.router.params.id,
          product_type_id: el,
        });
      });

      // update Variants
      if (values.category_attributes.length > 0) {
        this.props.updateGameVariant(values.category_attributes[0]?._id, {
          ...values.category_attributes[0],
          extras: this.state.attributes,
        });
      } else {
        const category_attributes = {
          name: 'Specification',
          image_url: '',
          category_id: this.props.router.params.id,
          extras: this.state.attributes,
        };
        this.props.createGameVariant(category_attributes);
      }
    } else {
      this.props.createGame(values, (result) => {
        if (result.data?._id) {
          // add variants
          const category_attributes = {
            name: 'Specification',
            image_url: '',
            category_id: result.data?._id,
            extras: this.state.attributes,
          };
          this.props.createGameVariant(category_attributes);

          // add product types
          (this.state.product_types || []).forEach((el) => {
            this.props.createGameProductType({
              category_id: result.data?._id,
              product_type_id: el,
            });
          });
        }
      });
    }
    createNotification('success', lang('success.update'));
    this.setState({ loading: false });
  };

  uploadImage = async (file, callback) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/categories/`);
    body.append('type_upload', 'product');
    body.append('name', 'product');
    const me = this;

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      .then((json) => callback(json?.data?.img_urls[0]))
      .catch((err) => console.log(err));
  };

  handleImageUpload = (file, callback) => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.uploadImage(file, callback);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  handleUploadIcon = (callback) => {
    const file = this.state.icon;
    if (file || this.state.image_url) {
      if (file) {
        this.handleImageUpload(file, (result) => {
          this.setState({ image_url: result }, () => callback());
        });
      } else if (this.state.image_url) {
        callback();
      }
    } else {
      callback();
    }
  };
  handleUploadCollection = (callback) => {
    const file = this.state.collection;
    if (file) {
      this.handleImageUpload(file, (result) => {
        this.setState({ collection_url: [result] }, () => callback());
      });
    } else {
      callback();
    }
  };

  onSelectDropdown(e) {
    const options = e.target.options || [];
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    this.setState({
      product_types: selectedValues,
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      this.setState({ loading: true });
      const values = { ...this.state.game };
      values.description = window.tinymce.get('description').getContent();
      values.intro = window.tinymce.get('introduction').getContent();
      values.category_tag = this.state.category_tag;
      values.image_url = this.state.image_url;
      values.collection_url = this.state.collection_url;
      values.icon = this.state.icon;
      values.collection = this.state.collection;
      delete values.product_types;

      try {
        await formValidation(values, requiredFields);
      } catch (err) {
        this.setState({ errors: err, loading: false });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      this.handleUploadIcon(() => this.handleUploadCollection(() => this.processData(values)));
    }
  }

  removeAttribute = (i) => {
    const attributes = this.state.attributes.filter((el, index) => index !== i);

    this.setState({
      attributes,
    });
  };

  saveAttributeValue = (i, value) => {
    const attributes = this.state.attributes;
    attributes[i].key = value;
    attributes[i].value = 'New value';
    this.setState({
      attributes,
    });
  };
  saveAttribute = (i, name, value) => {
    const attributes = this.state.attributes;
    attributes[i][name] = value;
    this.setState({
      attributes,
    });
  };

  addAttribute = () => {
    const attributes = this.state.attributes;
    attributes.push({
      key: '',
      value: 'New value',
      type: 'text',
      options: [],
    });
    this.setState({
      attributes,
    });
  };
  initImageUpload() {
    window.Dropzone.autoDiscover = false;
    const Selector = {
      DROPZONE: '#dropzone',
      DZ_ERROR_MESSAGE: '.dz-error-message',
      DZ_PREVIEW: '.dz-preview',
      DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
      DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover',
    };

    const ClassName = {
      DZ_FILE_PROCESSING: 'dz-file-processing',
      DZ_FILE_COMPLETE: 'dz-file-complete',
      DZ_COMPLETE: 'dz-complete',
      DZ_PROCESSING: 'dz-processing',
    };

    const Events = {
      ADDED_FILE: 'addedfile',
      REMOVED_FILE: 'removedfile',
      COMPLETE: 'complete',
    };

    const item = document.querySelector(Selector.DROPZONE);
    const that = this;
    const options = {
      url: '/assets/php/',
      addRemoveLinks: false,
      previewsContainer: item.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: item.querySelector(Selector.DZ_PREVIEW).innerHTML,
      thumbnailWidth: null,
      thumbnailHeight: null,
      maxFilesize: 1,
      autoProcessQueue: false,
      filesizeBase: 1000,
      error(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          if (typeof message !== 'string' && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
            node.textContent = message;
          }
        }
      },
    };
    item.querySelector(Selector.DZ_PREVIEW).innerHTML = '';

    const dropzone = new window.Dropzone(Selector.DROPZONE, options);

    dropzone.on(Events.ADDED_FILE, (e) => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_FILE_COMPLETE);
      }
      item.classList.add(ClassName.DZ_FILE_PROCESSING);
      that.setState({ icon: e });
    });
    dropzone.on(Events.REMOVED_FILE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }
      item.classList.add(ClassName.DZ_FILE_COMPLETE);
      that.setState({ icon: null });
    });
    dropzone.on(Events.COMPLETE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }

      item.classList.add(ClassName.DZ_FILE_COMPLETE);
    });
  }

  initCollectionUpload() {
    window.Dropzone.autoDiscover = false;
    const Selector = {
      DROPZONE: '#uploadCollection',
      DZ_ERROR_MESSAGE: '.dz-error-message',
      DZ_PREVIEW: '.dz-preview',
      DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
      DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover',
    };

    const ClassName = {
      DZ_FILE_PROCESSING: 'dz-file-processing',
      DZ_FILE_COMPLETE: 'dz-file-complete',
      DZ_COMPLETE: 'dz-complete',
      DZ_PROCESSING: 'dz-processing',
    };

    const Events = {
      ADDED_FILE: 'addedfile',
      REMOVED_FILE: 'removedfile',
      COMPLETE: 'complete',
    };

    const item = document.querySelector(Selector.DROPZONE);
    const that = this;
    const options = {
      url: '/assets/php/',
      addRemoveLinks: false,
      previewsContainer: item.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: item.querySelector(Selector.DZ_PREVIEW).innerHTML,
      thumbnailWidth: null,
      thumbnailHeight: null,
      maxFilesize: 1,
      autoProcessQueue: false,
      filesizeBase: 1000,
      error(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          if (typeof message !== 'string' && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
            node.textContent = message;
          }
        }
      },
    };
    item.querySelector(Selector.DZ_PREVIEW).innerHTML = '';

    const dropzone = new window.Dropzone(Selector.DROPZONE, options);

    dropzone.on(Events.ADDED_FILE, (e) => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_FILE_COMPLETE);
      }
      item.classList.add(ClassName.DZ_FILE_PROCESSING);
      that.setState({ collection: e });
    });
    dropzone.on(Events.REMOVED_FILE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }
      item.classList.add(ClassName.DZ_FILE_COMPLETE);
      that.setState({ collection: null });
    });
    dropzone.on(Events.COMPLETE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }

      item.classList.add(ClassName.DZ_FILE_COMPLETE);
    });
  }

  addChoice(index) {
    const attributes = this.state.attributes;
    attributes[index].options.push('');
    this.setState({
      attributes,
    });
  }

  handleInput = (e, index) => {
    e.preventDefault();
    e.target.style.height = 'auto';
    let height = e.target.scrollHeight < 300 ? e.target.scrollHeight : 300;
    e.target.style.height = height + 'px';
  };
  handleKeyUp = (e, index) => {
    if (e.keyCode == 13 && (e.shiftKey || e.altKey)) {
      if (e.type == 'keypress') {
        pasteIntoInput(this, '\n');
      }
    } else if (e.keyCode === 13) {
      const message = e.target.value;
      if ((message || '') === (this.state.attributes[index].options || []).join('\n')) {
        this.addChoice(index);
      } else {
        this.handleTextareaValue(message, index);
      }
    }
  };
  handleTextareaValue = (message, index) => {
    const validateMessage = (message || '').trim().split('\n');
    const attributes = this.state.attributes;
    if ((message || '') !== (attributes[index].options || []).join('\n')) {
      attributes[index].options = validateMessage;
      this.setState({
        attributes,
      });
    }
  };

  renderAttribute(attribute, index) {
    return (
      <div className="border-bottom border-translucent border-dashed border-sm-0 border-bottom-xl pb-4">
        <div className="d-flex flex-wrap mb-2">
          <h5 className="text-body-highlight me-2">Option {index + 1}</h5>
          <a className="fw-bold fs-9" href="#!" onClick={() => this.removeAttribute(index)}>
            Remove
          </a>
        </div>
        <input
          className="form-control mb-2"
          type="text"
          value={attribute?.key}
          onChange={(e) => this.saveAttributeValue(index, e.target.value)}
        />

        <select
          className="form-select mb-3"
          size="1"
          data-choices="data-choices"
          required="required"
          data-options='{"removeItemButton":false,"placeholder":true}'
          aria-label="category"
          value={attribute.type}
          onChange={(e) => {
            this.saveAttribute(index, 'options', []);
            this.saveAttribute(index, 'type', e.currentTarget.value);
          }}
        >
          <option value={'text'}>Text</option>
          <option value={'checkbox'}>Check box</option>
          <option value={'list'}>Dropdown List</option>
        </select>

        {attribute.type !== 'text' && (
          <div className="mb-2">
            <div className="d-flex flex-wrap mb-2">
              <h5 className="mb-2 me-2">Choices</h5>
            </div>
            <textarea
              className="form-control"
              placeholder="Please break line to add additional value"
              style={{ height: '50px', width: '100%' }}
              onKeyUp={(e) => {
                this.handleKeyUp(e, index);
                this.handleInput(e, index);
              }}
              onChange={(e) => {
                this.handleTextareaValue(e.target.value, index);
              }}
              value={(attribute.options || []).join('\n')}
            ></textarea>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.router.params.id) this.props.getGameDetail(this.props.router.params.id);
      this.props.getGameTypeLists();
      this.props.getProductTypeLists();
      this.initImageUpload();
      this.initCollectionUpload();
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.game?.description) window.tinymce.get('description').setContent(this.state.game?.description);
      if (this.state.game?.intro) window.tinymce.get('introduction').setContent(this.state.game?.intro);
    }, 200);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.gameDetail !== this.props.gameDetail && this.props.router.params.id) {
      this.setState({
        game: nextProps.gameDetail?.data,
        attributes:
          nextProps.gameDetail?.data?.category_attributes?.length > 0
            ? nextProps.gameDetail?.data?.category_attributes[0]?.extras
            : [],
        category_tag: nextProps.gameDetail?.data?.category_tag?._id,
        product_types: _.uniq(nextProps.gameDetail?.data?.product_types.map((el) => el.product_type_id) || []),
        image_url: nextProps.gameDetail?.data?.image_url,
        collection_url: nextProps.gameDetail?.data?.collection_url,
        extraMetaTitles: nextProps.gameDetail?.data?.meta_titles,
        extraMetaDescriptions: nextProps.gameDetail?.data?.meta_descriptions,
        extraDescriptions: nextProps.gameDetail?.data?.descriptions,
        extraIntroductions: nextProps.gameDetail?.data?.intros,
      });
    }
    if (nextProps.gameTypes !== this.props.gameTypes) {
      this.setState({ gameTypes: nextProps.gameTypes });
    }
    if (nextProps.productTypes !== this.props.productTypes) {
      this.setState({ productTypes: nextProps.productTypes });
    }
  }

  renderIntroductionBasedOnProductType() {
    const onChangeIntroduction = (slug, value) => {
      this.setState({
        extraIntroductions: {
          ...this.state.extraIntroductions,
          [slug]: value,
        },
      });
    };
    const types = this.state.productTypes.filter((type) => this.state.product_types.indexOf(type._id) >= 0);
    return types.map((type) => (
      <div key={type._id}>
        <ExtraDescription
          defaultValue={
            this.state.extraIntroductions && this.state.extraIntroductions[type.slug]
              ? this.state.extraIntroductions[type.slug]
              : ''
          }
          name="introduction"
          title="Introduction"
          type={type}
          onChange={onChangeIntroduction}
        />
      </div>
    ));
  }

  renderDescriptionBasedOnProductType() {
    const onChangeDescription = (slug, value) => {
      this.setState({
        extraDescriptions: {
          ...this.state.extraDescriptions,
          [slug]: value,
        },
      });
    };
    const types = this.state.productTypes.filter((type) => this.state.product_types.indexOf(type._id) >= 0);
    return types.map((type) => (
      <div key={type._id}>
        <ExtraDescription
          defaultValue={
            this.state.extraDescriptions && this.state.extraDescriptions[type.slug]
              ? this.state.extraDescriptions[type.slug]
              : ''
          }
          name="description"
          title="Description"
          type={type}
          onChange={onChangeDescription}
        />
      </div>
    ));
  }

  renderMetaDescriptionBasedOnProductType() {
    const onChangeDescription = (slug, value) => {
      this.setState({
        extraMetaDescriptions: {
          ...this.state.extraMetaDescriptions,
          [slug]: value,
        },
      });
    };
    const types = this.state.productTypes.filter((type) => this.state.product_types.indexOf(type._id) >= 0);
    return types.map((type) => (
      <div key={type._id} className="mb-6">
        <h4 className="mb-3">Meta Description ({type.name})</h4>
        <input
          className="form-control"
          type="text"
          value={
            this.state.extraMetaDescriptions && this.state.extraMetaDescriptions[type.slug]
              ? this.state.extraMetaDescriptions[type.slug]
              : ''
          }
          onChange={(e) => {
            onChangeDescription(type.slug, e.target.value);
          }}
        />
      </div>
    ));
  }

  renderMetaTitleBasedOnProductType() {
    const onChangeDescription = (slug, value) => {
      this.setState({
        extraMetaTitles: {
          ...this.state.extraMetaTitles,
          [slug]: value,
        },
      });
    };
    const types = this.state.productTypes.filter((type) => this.state.product_types.indexOf(type._id) >= 0);
    return types.map((type) => (
      <div key={type._id} className="mb-6">
        <h4 className="mb-3">Meta Title ({type.name})</h4>
        <input
          className="form-control"
          type="text"
          value={
            this.state.extraMetaTitles && this.state.extraMetaTitles[type.slug]
              ? this.state.extraMetaTitles[type.slug]
              : ''
          }
          onChange={(e) => {
            onChangeDescription(type.slug, e.target.value);
          }}
        />
      </div>
    ));
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} noValidate="">
          <div className="col-12 col-xl-8">
            <div className="mb-6">
              <h4 className="mb-3">Name</h4>
              <input
                className="form-control"
                id="inputFullName"
                type="text"
                required="required"
                value={this.state.game?.name}
                onChange={(e) => {
                  this.updateState('name', e.target.value);
                }}
              />
              <div className="invalid-feedback">Please enter name</div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Default introduction</h4>
              <textarea
                className="tinymce"
                name="content"
                id="introduction"
                data-tinymce='{"height":"15rem","placeholder":"Write a intro here..."}'
              ></textarea>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Default description</h4>
              <textarea
                className="tinymce"
                name="content"
                id="description"
                data-tinymce='{"height":"15rem","placeholder":"Write a default description here..."}'
              ></textarea>
            </div>
            {this.renderIntroductionBasedOnProductType()}
            {this.renderDescriptionBasedOnProductType()}
            <div className="mb-6">
              <h4 className="mb-3">Game Icon</h4>
              <div className="mb-2">
                <img src={this.state.image_url} style={{ height: '50px' }} />
              </div>
              <div
                id="dropzone"
                className="dropzone dropzone-multiple p-0"
                data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'
              >
                <div className="fallback">
                  <input type="file" name="file" />
                </div>
                <div className="dz-message text-center" data-dz-message="data-dz-message">
                  <div className="dz-message-text">
                    <img className="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />
                    Drop your file here
                  </div>
                </div>
                <div className="dz-preview dz-preview-multiple m-0 d-flex flex-column">
                  <div className="d-flex pb-3 border-bottom border-translucent media px-2">
                    <div className="border p-2 rounded-2 me-2">
                      <img
                        className="rounded-2 dz-image"
                        src="/assets/img/icons/file.png"
                        alt="..."
                        data-dz-thumbnail="data-dz-thumbnail"
                      />
                    </div>
                    <div className="flex-1 d-flex flex-between-center">
                      <div>
                        <h6 data-dz-name="data-dz-name"></h6>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 fs-9 text-body-quaternary lh-1" data-dz-size="data-dz-size"></p>
                          <div className="dz-progress">
                            <span className="dz-upload" data-dz-uploadprogress=""></span>
                          </div>
                        </div>
                        <span className="fs-10 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn btn-link text-body-quaternary btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="fas fa-ellipsis-h"></span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end border border-translucent py-2">
                          <a className="dropdown-item" href="#!" data-dz-remove="data-dz-remove">
                            Remove File
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Game Cover</h4>
              <div className="mb-2">
                <img
                  src={(this.state.collection_url || []).length > 0 && this.state.collection_url[0]}
                  style={{ height: '50px' }}
                />
              </div>
              <div
                className="dropzone dropzone-multiple p-0"
                data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'
                id="uploadCollection"
              >
                <div className="fallback">
                  <input type="file" name="file" />
                </div>
                <div className="dz-message text-center" data-dz-message="data-dz-message">
                  <div className="dz-message-text">
                    <img className="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />
                    Drop your file here
                  </div>
                </div>
                <div className="dz-preview dz-preview-multiple m-0 d-flex flex-column">
                  <div className="d-flex pb-3 border-bottom border-translucent media px-2">
                    <div className="border p-2 rounded-2 me-2">
                      <img
                        className="rounded-2 dz-image"
                        src="/assets/img/icons/file.png"
                        alt="..."
                        data-dz-thumbnail="data-dz-thumbnail"
                      />
                    </div>
                    <div className="flex-1 d-flex flex-between-center">
                      <div>
                        <h6 data-dz-name="data-dz-name"></h6>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 fs-9 text-body-quaternary lh-1" data-dz-size="data-dz-size"></p>
                          <div className="dz-progress">
                            <span className="dz-upload" data-dz-uploadprogress=""></span>
                          </div>
                        </div>
                        <span className="fs-10 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn btn-link text-body-quaternary btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="fas fa-ellipsis-h"></span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end border border-translucent py-2">
                          <a className="dropdown-item" href="#!" data-dz-remove="data-dz-remove">
                            Remove File
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Meta title</h4>
              <input
                className="form-control"
                type="text"
                value={this.state.game?.meta_title}
                onChange={(e) => {
                  this.updateState('meta_title', e.target.value);
                }}
              />
            </div>
            {this.renderMetaTitleBasedOnProductType()}
            <div className="mb-6">
              <h4 className="mb-3">Meta Description</h4>
              <input
                className="form-control"
                type="text"
                value={this.state.game?.meta_description}
                onChange={(e) => {
                  this.updateState('meta_description', e.target.value);
                }}
              />
            </div>
            {this.renderMetaDescriptionBasedOnProductType()}
            <div className="col-12">
              {!this.state.loading ? (
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              ) : (
                <button className="btn btn-primary" type="button" disabled="">
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              )}
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="row g-2">
              <div className="col-12 col-xl-12">
                <div className="card mb-3">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Organize</h4>
                    <div className="row gx-3">
                      <div className="col-12 col-sm-6 col-xl-12">
                        {this.state.gameTypes.length > 0 && (
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2">
                              <h5 className="mb-0 text-body-highlight me-2">Game Type</h5>
                              <a className="fw-bold fs-9" href="/admin/games/types/add">
                                Add new game type
                              </a>
                            </div>
                            <select
                              className="form-select mb-3"
                              size="1"
                              data-choices="data-choices"
                              required="required"
                              data-options='{"removeItemButton":false,"placeholder":true}'
                              aria-label="category"
                              onChange={(e) => this.setState({ category_tag: e.target.value })}
                            >
                              <option>Select Game Type</option>
                              {this.state.gameTypes.map((gameType) => (
                                <option
                                  key={gameType?._id}
                                  selected={this.state.category_tag == gameType?._id}
                                  value={gameType?._id}
                                >
                                  {gameType.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6 col-xl-12">
                        {this.state.productTypes.length > 0 && (
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2">
                              <h5 className="mb-0 text-body-highlight me-2">Product Type</h5>
                              <a className="fw-bold fs-9" href="/admin/games/product-types/add">
                                Add new product type
                              </a>
                            </div>
                            <select
                              className="form-select mb-3"
                              data-choices="data-choices"
                              required="required"
                              multiple
                              onChange={(e) => this.onSelectDropdown(e)}
                            >
                              {this.state.productTypes.map((gameType) => (
                                <option
                                  selected={this.state.product_types.indexOf(gameType?._id) >= 0}
                                  value={gameType?._id}
                                  key={gameType?._id}
                                >
                                  {gameType.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Variants</h4>
                    <div className="row g-3" style={{ maxHeight: 400, overflow: 'scroll' }}>
                      {this.state.attributes.map((attribute, index) => (
                        <div className="col-12 col-sm-6 col-xl-12" key={`attribute-${index}`}>
                          {this.renderAttribute(attribute, index)}
                        </div>
                      ))}
                    </div>
                    <button className="btn btn-phoenix-primary w-100" type="button" onClick={() => this.addAttribute()}>
                      Add another option
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

FormGames.propTypes = {
  auth: PropTypes.object.isRequired,
  gameDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createGame: PropTypes.func.isRequired,
  updateGame: PropTypes.func.isRequired,
  getGameDetail: PropTypes.func.isRequired,
  getGameTypeLists: PropTypes.func.isRequired,
  getProductTypeLists: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  gameDetail: state.games.game,
  gameTypes: state.gameTypes?.gameTypes?.data ? state.gameTypes?.gameTypes?.data : [],
  productTypes: state.productTypes?.productTypes?.data ? state.productTypes?.productTypes?.data : [],
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createGame,
  updateGame,
  getGameDetail,
  getGameTypeLists,
  getProductTypeLists,
  createGameProductType,
  removeGameProductType,
  createGameVariant,
  updateGameVariant,
})(withRouter(FormGames));
