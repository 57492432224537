import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import jwtDecode from 'jwt-decode';
import config from './config';
import { setAuthToken } from './utils/helpers';
import { NotificationContainer } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

// Global Components
import AdminRoute from './components/common/AdminRoute';
import LoadingBar from 'react-redux-loading-bar';
import { logoutUser, setCurrentUser, clearCurrentProfile } from './actions/auth';

// Route Page
import Login from './components/auth/Login';
import ModalRoute from './components/common/ModalRoute';

// Check for token
if (sessionStorage.getItem(config.tokenKey)) {
  // Set auth token header auth
  setAuthToken(sessionStorage.getItem(config.tokenKey));
  // Decode token and get user info and exp
  const decoded = jwtDecode(sessionStorage.getItem(config.tokenKey));
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <React.Fragment>
            <NotificationContainer />
            <LoadingBar scope="sectionBar" />
            <Routes>
              <Route exact path="/*" element={<Login />} />
              <Route path="/admin/*" element={<AdminRoute path="/*" />} />
              <Route path="/modal/*" element={<ModalRoute path="/*" />} />
            </Routes>
          </React.Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
