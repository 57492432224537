import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import queryString from 'query-string';
import { Modal } from 'bootstrap';

import { getTransactionsLists, updateTransaction } from '../../../actions/transaction';
import { getSellerLists } from '../../../actions/seller';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../utils/constants';
import { withRouter } from '../../../withRouter';
import ChatModal from './ChatModal';
import TransactionsTable from './TransactionsTable';

const filterKey = {
  1: 'Pending',
  2: 'Awaiting for Payment',
  11: 'Processing Payment',
  3: 'Paid',
  4: 'Completed',
  6: 'Payment Failed',
  5: 'Refund',
  10: 'Partial Refund',
  7: 'Delivered',
  8: 'Suspended',
  9: 'On Hold',
};

const statuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Paid',
  'Completed',
  'Refund',
  'Fail',
  'Delivered',
  'Suspended',
  'On Hold',
  'Partial Refund',
  'Processing Payment',
];

const statusesColor = [
  'default',
  'warning',
  'warning',
  'success',
  'success',
  'warning',
  'danger',
  'success',
  'danger',
  'success',
  'success',
  'success',
];

class Transactions extends Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search);
    const initialPage = parseInt(params.page) || 1;
    const initialPageSize = parseInt(params.pageSize) || DEFAULT_PAGE_SIZE;

    this.state = {
      filter: {
        page_size: initialPageSize,
        page: initialPage,
        date_from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        date_to: moment().add(1, 'days').format('YYYY-MM-DD'),
      },
      transactionList: [],
      originalTransactionList: [],
      total_items: 0,
      activeStatus: '',
      sellers: [],
      date_from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      date_to: moment().add(1, 'days').format('YYYY-MM-DD'),
      csvData: [],
      loadingCsv: false,
      order: null,
      initialPage,
      exportDateFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      exportDateTo: moment().add(1, 'days').format('YYYY-MM-DD'),
      showExportModal: false,
      isExporting: false,
      showChatModal: false,
      showProofModal: false,
    };
    this.setActiveStatus = this.setActiveStatus.bind(this);
  }

  initializeModals = () => {
    const chatModalElement = document.getElementById('chatModal');
    const proofModalElement = document.getElementById('scrollingLong');

    if (chatModalElement && !this.chatModal) {
      this.chatModal = new Modal(chatModalElement, {
        backdrop: 'static',
        keyboard: false
      });
    }

    if (proofModalElement && !this.proofModal) {
      this.proofModal = new Modal(proofModalElement, {
        backdrop: 'static',
        keyboard: false
      });
    }
  };

  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            page: page.selected + 1,
          },
          csvData: [],
          loadingCsv: true,
        },
        () => {
          this.props.getTransactionsLists({
            ...this.state.filter,
            page: page.selected + 1,
          });
        },
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  generateCSV = (transactionList) => {
    const result = [
      [
        'Order ID',
        'Status',
        'Created Time',
        'Seller Name',
        'Seller ID',
        'Product Name',
        'Game',
        'Type',
        'Fee',
        'Price',
        'Refund',
        'Disbursement',
        'Total',
      ],
    ];
    transactionList.forEach((el) => {
      result.push([
        el._id,
        statuses[el.status],
        moment(el.created_time * 1000).format('YYYY-MM-DD'),
        el.seller_info.seller_name,
        el.seller_id.slice(0, 8),
        el.item_name,
        el.category_name,
        el.product_type_name,
        el.fee_charge_seller,
        el.price,
        el.refund,
        el.disbursement,
        el.price - el.price * (el.fee_charge_seller / 100),
      ]);
    });
    this.setState({ csvData: result, isExporting: false, showExportModal: false }, () => {
      // If we have CSV data and we're not loading, trigger the download
      if (result.length > 1) {
        const link = document.createElement('a');
        const csvContent = result.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Transactions_${this.state.exportDateFrom}_to_${this.state.exportDateTo}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  onChangePageSize = (value) => {
    const newPageSize = parseInt(value);
    this.setState(
      {
        filter: {
          ...this.state.filter,
          page_size: newPageSize,
          page: 1,
        },
        initialPage: 1,
      },
      () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', '1');
        urlParams.set('pageSize', newPageSize);
        window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());

        this.props.getTransactionsLists(this.state.filter);
      }
    );
  };

  searchTransaction = (e) => {
    const keyword = e.target.value;
    this.setState(
      {
        loadingCsv: true,
        filter: {
          ...this.state.filter,
          page: 1,
          s: keyword,
        },
        initialPage: 1,
      },
      () => {
        this.props.getTransactionsLists(this.state.filter);
      }
    );
  };

  filterTransaction = (seller_id) => {
    this.setState(
      {
        loadingCsv: true,
      },
      () => {
        const items = [...this.state.originalTransactionList].filter((el) => el.seller_id == seller_id);
        this.setState({ transactionList: items });
        this.generateCSV(items);
      },
    );
  };

  setActiveStatus(status) {
    this.setState(
      {
        activeStatus: status,
        filter: {
          date_from: this.state.date_from,
          date_to: this.state.date_to,
          status,
          page_size: DEFAULT_PAGE_SIZE,
          page: 1,
        },
        initialPage: 1,
      },
      () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', '1');
        window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());

        this.props.getTransactionsLists(this.state.filter);
        window.choices.clearStore();
      },
    );
  }

  handleViewChat = (order) => {
    this.setState({
      order: order || null,
      showChatModal: true
    }, () => {
      // Add modal-open class to body
      document.body.classList.add('modal-open');
    });
  };

  handleViewProof = (order) => {
    this.setState({
      order: order || null,
      showProofModal: true
    }, () => {
      // Add modal-open class to body
      document.body.classList.add('modal-open');
    });
  };

  handleCloseChatModal = () => {
    this.setState({
      showChatModal: false
    });
    // Remove backdrop
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
    // Remove modal-open class from body
    document.body.classList.remove('modal-open');
  };

  handleCloseProofModal = () => {
    this.setState({
      showProofModal: false
    });
    // Remove backdrop
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
    // Remove modal-open class from body
    document.body.classList.remove('modal-open');
  };

  componentDidMount() {
    this.props.getTransactionsLists(this.state.filter);

    // Initialize modals after a short delay to ensure DOM is ready
    setTimeout(() => {
      this.initializeModals();

      const that = this;
      const element = document.querySelector('#seller');

      if (element && !window.choices) {
        window.choices = new window.Choices(element, {
          silent: true,
          searchEnabled: true,
          searchChoices: false,
          placeholder: true,
          placeholderValue: 'Search Seller',
          searchPlaceholderValue: 'Search Seller...',
          choices: [],
        });
        window.choices.clearChoices();
        element.addEventListener(
          'search',
          function (event) {
            that.onInput(event.detail.value);
          },
          false,
        );
      }
    }, 1000);
  }

  componentWillUnmount() {
    // Clean up modals
    if (this.chatModal) {
      this.chatModal.dispose();
      this.chatModal = null;
    }
    if (this.proofModal) {
      this.proofModal.dispose();
      this.proofModal = null;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.transactions !== this.props.transactions) {
      this.setState(
        {
          ...this.state,
          transactionList: nextProps.transactions.transactions.data,
          originalTransactionList: nextProps.transactions.transactions.data,
          total_items: nextProps.transactions.transactions.total_items,
        },
        () => {
          // Only generate CSV if we're in export mode
          if (this.state.showExportModal) {
            this.generateCSV(nextProps.transactions.transactions.data);
          }
        },
      );
    }

    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.clearChoices();
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }

  handleTablePageChange = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            page,
          },
          initialPage: page,
          csvData: [],
          loadingCsv: true,
        },
        () => {
          this.props.getTransactionsLists({
            ...this.state.filter,
            page,
          });
        },
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  handleExportSubmit = () => {
    this.setState({
      isExporting: true,
      showExportModal: true,
      filter: {
        ...this.state.filter,
        date_from: this.state.exportDateFrom,
        date_to: this.state.exportDateTo,
      },
    }, () => {
      this.props.getTransactionsLists(this.state.filter);
    });
  };

  render() {
    const totalPages = Math.ceil(this.state.total_items / this.state.filter.page_size);

    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Transactions</li>
          </ol>
        </nav>
        <div id="advanceTable">
          <div className="row align-items-center justify-content-between g-3 mb-3">
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-1">
                <form className="position-relative" data-bs-display="static">
                  <input
                    className="form-control search-input"
                    type="search"
                    placeholder="Search by Order ID"
                    aria-label="Search"
                    onChange={this.searchTransaction}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                className="form-select ms-1"
                id="organizerSingle"
                data-choices="data-choices"
                data-options='{"removeItemButton":false,"placeholder":true}'
                onChange={(e) => this.setActiveStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {Object.keys(filterKey).map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {filterKey[value]}
                  </option>
                ))}
              </select>
              <div className="ms-1">
                <select
                  id="seller"
                  className="form-select"
                  onChange={(e) => this.filterTransaction(e.target.value)}
                ></select>
              </div>
              <div className="ms-1">
                <select id="pageSize" className="form-select" onChange={(e) => this.onChangePageSize(e.target.value)}>
                  {DEFAULT_PAGE_SIZE_OPTIONS.map((el) => (
                    <option selected={this.state.filter.page_size == el} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-link text-body me-4 px-0"
                  data-bs-toggle="modal"
                  data-bs-target="#exportModal"
                >
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start g-3 mb-2">
            <div>
              <DatePicker
                placeholderText="From Date"
                selected={this.state.date_from}
                onChange={(dateStr) => {
                  this.setState({ date_from: dateStr });
                  if (this.state.date_to) {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          date_from: moment(dateStr).format('YYYY-MM-DD'),
                          date_to: moment(this.state.date_to).format('YYYY-MM-DD'),
                        },
                      },
                      () => {
                        this.paginationHandler({ selected: 0 });
                      },
                    );
                  }
                }}
              />
            </div>
            <div className="ms-3">
              <DatePicker
                placeholderText="To Date"
                selected={this.state.date_to}
                onChange={(dateStr) => {
                  this.setState({ date_to: dateStr });
                  if (this.state.date_from) {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          date_to: moment(dateStr).format('YYYY-MM-DD'),
                          date_from: moment(this.state.date_from).format('YYYY-MM-DD'),
                        },
                      },
                      () => {
                        this.paginationHandler({ selected: 0 });
                      },
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <TransactionsTable
              data={this.state.transactionList}
              settings={this.props.settings}
              pageSize={this.state.filter.page_size}
              initialPage={this.state.initialPage}
              onPageChange={this.handleTablePageChange}
            />
          </div>
        </div>
        <div className="modal fade" id="exportModal" tabIndex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exportModalLabel">
                  Export Transactions
                </h5>
                <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <span className="fas fa-times fs-9"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">From Date</label>
                  <DatePicker
                    className="form-control"
                    selected={moment(this.state.exportDateFrom).toDate()}
                    onChange={(date) => this.setState({ exportDateFrom: moment(date).format('YYYY-MM-DD') })}
                    dateFormat="yyyy-MM-dd"
                    maxDate={moment(this.state.exportDateTo).toDate()}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">To Date</label>
                  <DatePicker
                    className="form-control"
                    selected={moment(this.state.exportDateTo).toDate()}
                    onChange={(date) => this.setState({ exportDateTo: moment(date).format('YYYY-MM-DD') })}
                    dateFormat="yyyy-MM-dd"
                    minDate={moment(this.state.exportDateFrom).toDate()}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.handleExportSubmit}
                  disabled={this.state.isExporting}
                >
                  {this.state.isExporting ? 'Exporting...' : 'Export'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Transactions.propTypes = {
  auth: PropTypes.object.isRequired,
  transactions: PropTypes.object.isRequired,
  getTransactionsLists: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getTransactionsLists,
  getSellerLists,
  updateTransaction,
})(withRouter(Transactions));
