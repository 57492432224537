/* eslint-disable */
const advanceAjaxTableInit = (options, orders = null) => {
  const togglePaginationButtonDisable = (button, disabled) => {
    button.disabled = disabled;
    button.classList[disabled ? 'add' : 'remove']('disabled');
  };
  // Selectors
  const table = document.getElementById('advanceTable');

  if (table) {
    const paginationButtonNext = table.querySelector('[data-list-pagination="next"]');
    const paginationButtonPrev = table.querySelector('[data-list-pagination="prev"]');
    const viewAll = table.querySelector('[data-list-view="*"]');
    const viewLess = table.querySelector('[data-list-view="less"]');
    const listInfo = table.querySelector('[data-list-info]');
    const listFilter = document.querySelector('[data-list-filter]');
    const orderList = new window.List(table, options);
    if (orders !== null) {
      orderList.clear();
      orderList.add(orders);
    }

    // Fallback
    orderList.on('updated', (item) => {
      const fallback = table.querySelector('.fallback') || document.getElementById(options.fallback);

      if (fallback) {
        if (item.matchingItems.length === 0) {
          fallback.classList.remove('d-none');
        } else {
          fallback.classList.add('d-none');
        }
      }
    });

    const totalItem = orderList.items.length;
    const itemsPerPage = orderList.page;
    const btnDropdownClose = orderList.listContainer.querySelector('.btn-close');
    let pageQuantity = Math.ceil(totalItem / itemsPerPage);
    let numberOfcurrentItems = orderList.visibleItems.length;
    let pageCount = 1;

    btnDropdownClose && btnDropdownClose.addEventListener('search.close', () => orderList.fuzzySearch(''));

    const updateListControls = () => {
      listInfo && (listInfo.innerHTML = `Total ${totalItem} items`);
      paginationButtonPrev && togglePaginationButtonDisable(paginationButtonPrev, pageCount === 1);
      paginationButtonNext && togglePaginationButtonDisable(paginationButtonNext, pageCount === pageQuantity);

      if (pageCount > 1 && pageCount < pageQuantity) {
        togglePaginationButtonDisable(paginationButtonNext, false);
        togglePaginationButtonDisable(paginationButtonPrev, false);
      }
    };
    updateListControls();

    if (paginationButtonNext) {
      paginationButtonNext.addEventListener('click', (e) => {
        e.preventDefault();
        pageCount += 1;

        const nextInitialIndex = orderList.i + itemsPerPage;
        console.log(nextInitialIndex, orderList.size(), orderList.visibleItems.length, itemsPerPage, orderList.i);
        nextInitialIndex <= orderList.size() && orderList.show(nextInitialIndex, itemsPerPage);
        numberOfcurrentItems += orderList.visibleItems.length;
        updateListControls();

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', pageCount);
        window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());
      });
    }

    if (paginationButtonPrev) {
      paginationButtonPrev.addEventListener('click', (e) => {
        e.preventDefault();
        pageCount -= 1;

        numberOfcurrentItems -= orderList.visibleItems.length;
        const prevItem = orderList.i - itemsPerPage;
        prevItem > 0 && orderList.show(prevItem, itemsPerPage);
        updateListControls();

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', pageCount);
        window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());
      });
    }

    const toggleViewBtn = () => {
      viewLess.classList.toggle('d-none');
      viewAll.classList.toggle('d-none');
    };

    if (viewAll) {
      viewAll.addEventListener('click', () => {
        orderList.show(1, totalItem);
        pageQuantity = 1;
        pageCount = 1;
        numberOfcurrentItems = totalItem;
        updateListControls();
        toggleViewBtn();
      });
    }
    if (viewLess) {
      viewLess.addEventListener('click', () => {
        orderList.show(1, itemsPerPage);
        pageQuantity = Math.ceil(totalItem / itemsPerPage);
        pageCount = 1;
        numberOfcurrentItems = orderList.visibleItems.length;
        updateListControls();
        toggleViewBtn();
      });
    }
    console.log(options);
    if (options.pagination) {
      table.querySelector('.pagination').addEventListener('click', (e) => {
        if (e.target.classList[0] === 'page') {
          pageCount = Number(e.target.innerText);
          const nextInitialIndex = (pageCount - 1) * itemsPerPage + 1;
          nextInitialIndex <= orderList.size() && orderList.show(nextInitialIndex, itemsPerPage);
          numberOfcurrentItems =
            nextInitialIndex + itemsPerPage > orderList.size() ? orderList.size() : nextInitialIndex + itemsPerPage;
          updateListControls();

          if (options.currentPage) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('page', pageCount);
            window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());
          }
        }
      });
    }
    if (options.filter) {
      const { key } = options.filter;
      listFilter.addEventListener('change', (e) => {
        orderList.filter((item) => {
          if (e.target.value === '') {
            return true;
          }
          return item.values()[key].toLowerCase().includes(e.target.value.toLowerCase());
        });
      });
    }
    if (options.currentPage) {
      setTimeout(() => {
        const nextInitialIndex = (options.currentPage - 1) * itemsPerPage + 1;
        nextInitialIndex <= orderList.size() && orderList.show(nextInitialIndex, itemsPerPage);
        numberOfcurrentItems =
          nextInitialIndex + itemsPerPage > orderList.size() ? orderList.size() : nextInitialIndex + itemsPerPage;

        pageCount = Number(options.currentPage);
        updateListControls();
      }, 100);
    }
    return orderList;
  }
};

export default advanceAjaxTableInit;
