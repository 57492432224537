export default function ChatModal({ orderId }) {
  return (
    <div className="modal-dialog" style={{ maxWidth: '90%', height: '90vh' }}>
      <div className="modal-content" style={{ height: '100%' }}>
        <div className="modal-header">
          <span className="modal-title" id="scrollingLongModalLabel">
            Order ID: {orderId}
          </span>
          <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span className="fas fa-times fs-9"></span>
          </button>
        </div>
        <div className="modal-body">
          <iframe src={`/modal/marketplace/chat/${orderId}`} width={'100%'} height={'100%'} />
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
