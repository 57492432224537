import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { withRouter } from '../../../withRouter';
import { getSupportLists, getSupportDetail, deleteSupport } from '../../../actions/ticket';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import advanceAjaxTableInit from '../../../utils/advanceAjaxTableInit';
import { beforeRequest, formatDateTime, imgModal } from '../../../utils/helpers';

const filterKey = ['Open', 'Close'];
const statusesColor = {
  Open: 'success',
  Close: 'dark',
};

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        ticket_type: this.props.type,
        status: 'Open',
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      supportList: [],
      total_items: 0,
      activeStatus: 'Open',
      loading: true,
    };
    this.setCurrentSupport = this.setCurrentSupport.bind(this);
  }
  setCurrentSupport = (e, data) => {
    e.preventDefault();
    // this.props.getSupportDetail(data);
    this.props.router.navigate(`../support/view/${data._id}`, {
      replace: true,
    });
  };
  onChangeStatus = (status) => {
    this.setState(
      {
        ...this.state,
        activeStatus: status,
        supportList: [],
        loading: true,
        filter: {
          ...this.state.filter,
          status: status,
        },
      },
      () => {
        this.props.getSupportLists(this.state.filter);
      },
    );
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getSupportLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deleteSupport(id);
      window.tableList.remove('_id', id);
    }
    return false;
  };
  generateTable = () => {
    const options = {
      page: 12,
      valueNames: ['object_id', 'created_by', '_id', 'status'],
      filter: { key: 'status' },
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
    };

    window.tableList = advanceAjaxTableInit(options);
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
    this.generateTable();
  }
  componentDidUpdate() {
    // this.generateTable(this.state.supportList);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tickets !== this.props.tickets) {
      this.setState({
        ...this.state,
        supportList: nextProps.tickets.supports.data,
        loading: false,
      });
    }
    if (this.props.type !== nextProps.type) {
      this.setState(
        {
          ...this.state,
          filter: {
            ticket_type: nextProps.type,
            status: 'Open',
            page: 1,
            page_size: DEFAULT_PAGE_SIZE,
          },
          activeStatus: 'Open',
          loading: true,
          supportList: [],
        },
        () => {
          this.paginationHandler({ selected: 0 });
        },
      );
    }
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">{this.props.title}</li>
          </ol>
        </nav>
        {/* <h2 class="text-bold text-body-emphasis mb-5">{this.props.title}</h2> */}
        <div>
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto d-flex flex-row">
              <div class="search-box me-1">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    class="form-control search-input search fuzzy-search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                class="form-select"
                style={{ width: '300px' }}
                onChange={(e) => this.onChangeStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {filterKey.map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <a href="/admin/support/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add ticket
                </a>
              </div>
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      STATUS
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="countdown"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      TIME LEFT
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="action"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      ACTION
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="reason"
                      style={{ width: '30%', minWidth: '300px' }}
                    >
                      OVERVIEW
                    </th>

                    {this.props.type == 'Dispute' && (
                      <>
                        <th
                          class="sort align-middle text-center"
                          scope="col"
                          data-sort="seller"
                          style={{ width: '10%', minWidth: '150px' }}
                        >
                          SELLER
                        </th>
                        <th
                          class="sort align-middle text-center"
                          scope="col"
                          data-sort="buyer"
                          style={{ width: '10%', minWidth: '150px' }}
                        >
                          BUYER
                        </th>
                      </>
                    )}
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="ticket"
                      style={{ width: '10%', minWidth: '50px' }}
                    >
                      INFO
                    </th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body">
                  {(this.state.supportList || []).length > 0 ? (
                    this.state.supportList.map((method) => (
                      <tr key={method._id} class="hover-actions-trigger btn-reveal-trigger position-static">
                        <td class=" align-middle white-space-nowrap text-center">
                          <span
                            class={`status badge badge-phoenix fs-10 badge-phoenix-${statusesColor[method.status]
                              } d-inline-flex align-items-center`}
                          >
                            {method.status}
                          </span>
                        </td>
                        <td class="countdown align-middle text-break text-center">
                          <span className="text-danger">
                            <Countdown date={(method.ticket_type !== 'Dispute' ? method.updated_time : method.created_time) * 1000 + 48 * 3600000} />
                          </span>
                        </td>
                        <td class="action align-middle white-space-nowrap text-body-tertiary text-start text-center">
                          <button class="px-2 btn btn-info" onClick={(e) => this.setCurrentSupport(e, method)}>
                            <i class="fa-solid fa-eye me-1"></i> View
                          </button>
                          {method.ticket_type !== 'Dispute' && (
                            <button
                              class="px-2 ml-2 btn btn-danger deleteGame"
                              onClick={() => this.handleDelete(method._id)}
                            >
                              <i class="fa-solid fa-trash me-1"></i> Delete
                            </button>
                          )}
                        </td>
                        <td class="reason align-middle text-break">{method.subject}</td>

                        {this.props.type == 'Dispute' && (
                          <>
                            <td class="seller align-middle text-break text-center">{method?.seller_info?.user_name}</td>
                            <td class="buyer align-middle text-break text-center">{method?.buyer_info?.user_name}</td>
                          </>
                        )}
                        <td class="ticket align-middle white-space-nowrap">
                          <div class="d-flex align-items-center text-body text-hover-1000">
                            <div class="mb-0 ms-3">
                              <strong>
                                {method.ticket_type == 'Dispute' ? 'Dispute Create Date' : 'Ticket Create Date'}
                              </strong>
                              : {formatDateTime(method.created_time * 1000)}
                              <br />
                              <strong> {method.ticket_type == 'Dispute' ? 'Dispute ID' : 'Ticket ID'}: </strong>{' '}
                              <span class="_id">{method._id}</span>
                              <br />
                              {method.object_id && method.ticket_type == 'Dispute' && (
                                <>
                                  <strong>Order ID</strong>: <span class="object_id">{method?.object_id}</span>
                                  <br />
                                </>
                              )}
                              <strong>Created By</strong>:{' '}
                              <span class="created_by">{method?.user_info?.user_name}</span>
                              <br />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={this.props.type == 'Dispute' ? 8 : 6}>
                        {this.state.loading ? 'Loading...' : 'No data found'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div class="table-list row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a class="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a class="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div class="col-auto d-flex">
                <button class="page-link" data-list-pagination="prev">
                  <span class="fas fa-chevron-left"></span>
                </button>
                <ul class="mb-0 pagination"></ul>
                <button class="page-link pe-0" data-list-pagination="next">
                  <span class="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Support.propTypes = {
  auth: PropTypes.object.isRequired,
  tickets: PropTypes.object.isRequired,
  getSupportLists: PropTypes.func.isRequired,
  getSupportDetail: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  tickets: state.tickets,
});

export default connect(mapStatetoProps, {
  getSupportLists,
  getSupportDetail,
  deleteSupport,
})(withRouter(Support));
