import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChatScreen from '../marketplace/chat';

const ModalRoute = ({ auth }) => (
  <>
    {auth.isAuthenticated === true ? (
      <main className="main" id="top">
        <Routes>
          <Route path="/marketplace/chat/:id" element={<ChatScreen />} />
        </Routes>
      </main>
    ) : (
      <Navigate to="/" />
    )}
  </>
);

ModalRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalRoute);
