import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';

import { getProductLists, deleteProduct, deleteBulkProduct, boostProduct } from '../../../actions/products';
import { getSellerLists } from '../../../actions/seller';

import { convertCurrency, getUserId, formatDateTime } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

const DEFAULT_PAGE_SIZE = 50;
const pages = [10, 20, 50, 100, 200, 500, 1000];

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      productList: [],
      total_items: 0,
      selectedProduct: null,
      priority: 1,
      loading: false,
      sellers: [],
      product: {},
      product_ids: [],
      isLoaded: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleBulkDelete = this.handleBulkDelete.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
    this.paginationHandler = this.paginationHandler.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  onChangePageSize = (value) => {
    this.setState({ loading: true, loadingCsv: true }, () => {
      this.paginationHandler({ selected: 0 }, value);
    });
  };

  setProduct = (item) => {
    this.setState({
      product: item,
    });
  };
  handleBoost = async () => {
    await this.props.boostProduct(this.state.selectedProduct._id);
    this.paginationHandler({ selected: this.state.filter.page - 1 });
  };

  searchProduct = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          s: keyword,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  paginationHandler = (page, page_size = DEFAULT_PAGE_SIZE) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getProductLists({
        ...this.state.filter,
        page: page.selected + 1,
        page_size,
      });
      this.setState({
        ...this.state,
        isLoaded: true,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
          page_size,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
      });
    }
  };
  handleBulkDelete = async () => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      await this.props.deleteBulkProduct(this.state.product_ids);
      this.setState({
        ...this.state,
        product_ids: [],
      });
      window.location.reload();
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  selectBulk = (id) => {
    let items = [...this.state.product_ids];
    if (items.indexOf(id) >= 0) {
      items.splice(items.indexOf(id), 1);
    } else {
      items.push(id);
    }
    this.setState({
      product_ids: items,
    });
  };

  handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      await this.props.deleteProduct(id);
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  componentDidUpdate() {
    setTimeout(() => {
      const that = this;
      const element = document.querySelector('#seller');

      if (element && !window.choices) {
        window.choices = new window.Choices(element, {
          silent: true,
          searchEnabled: true,
          searchChoices: false,
          placeholder: true,
          placeholderValue: 'Seller Name or Seller ID',
          searchPlaceholderValue: 'Seller Name or Seller ID',
          removeItemButton: true, // Hiển thị nút xóa
          resetScrollPosition: true, // Đưa dropdown về đầu khi tìm kiếm
          choices: [],
        });

        // Xóa các lựa chọn ban đầu
        window.choices.clearChoices();

        // Lắng nghe sự kiện "search" khi người dùng nhập
        element.addEventListener(
          'search',
          function (event) {
            that.onInput(event.detail.value);
          },
          false,
        );

        // Lắng nghe sự kiện khi xóa giá trị tìm kiếm
        element.addEventListener(
          'removeItem',
          function () {
            that.clearSearch(); // Gọi hàm clearSearch để đặt lại dropdown
          },
          false,
        );
      }
    }, 500);
  }

  // Hàm clearSearch để đặt lại trạng thái ban đầu
  clearSearch() {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          created_by: '', // Xóa giá trị tìm kiếm người bán
        },
      },
      () => {
        this.paginationHandler({ selected: 0 }); // Đặt lại phân trang (nếu cần)
      },
    );
  }

  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);
  filterProductBySeller = (seller_id) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          created_by: seller_id,
        },
      },
      () => {
        this.paginationHandler({
          selected: 0,
        });
      },
    );
  };
  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    page = page > 0 ? page - 1 : 0;
    const page_size = parseInt(query.page_size) || DEFAULT_PAGE_SIZE;
    const s = query.keyword;
    if (s) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            s: s,
            page_size,
          },
        },
        () => {
          this.paginationHandler({ selected: page }, page_size);
        },
      );
    } else {
      this.paginationHandler({ selected: page }, page_size);
    }

    this.props.getSellerLists({
      field_name: 'kyc_status',
      field_value: 2,
      page: 1,
      page_size: 20,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.products !== this.props.products) {
      this.setState({
        ...this.state,
        productList: nextProps.products.products.data.products,
        total_items: nextProps.products.products.data.total_items,
      });
    }
    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const arg = {
      page: page.selected + 1,
      page_size: this.state.filter.page_size,
    };
    if (this.state.filter.s) {
      arg.keyword = this.state.filter.s;
    }
    if (this.state.filter.created_by) {
      arg.created_by = this.state.filter.created_by;
    }
    const params = new URLSearchParams(arg);
    const url = `/admin/games/products?${params}`;
    window.location.href = url;
  }
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Products</li>
          </ol>
        </nav>
        {/* <h2 className="text-bold text-body-emphasis mb-5">Products</h2> */}
        <div
          // id="advanceTable"
          data-list='{"valueNames":["name","slug","priority"],"page":200,"pagination":{"innerWindow":2,"left":1,"right":1}}'
        >
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div><strong>Total Product: {this.state.total_items}</strong></div>
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-2">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search by Product Name"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchProduct(event.target.value)}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <div>
                <select
                  id="seller"
                  className="form-select mb-3"
                  style={{ width: 200 }}
                  onChange={(e) => this.filterProductBySeller(e.target.value)}
                >
                  {/* <option value={''}>Seller</option> */}
                </select>
              </div>
              <div>
                <select
                  id="pageSize"
                  className="form-select ms-2"
                  onChange={(e) => this.onChangePageSize(e.target.value)}
                >
                  {pages.map((page) => (
                    <option selected={page == this.state.filter.page_size} value={page}>
                      {page}
                    </option>
                  ))}
                </select>
              </div>

            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                {this.state.product_ids.length > 0 && (
                  <button onClick={this.handleBulkDelete} className="btn btn-danger me-4">
                    Delete selected ({this.state.product_ids.length})
                  </button>
                )}
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
                <a href="products/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>

          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8"></div>
                    </th>
                    <th
                      className="sort align-middle text-start white-space-nowrap"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '10%', minWidth: '80px' }}
                    >
                      CREATED DATE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '20%', minWidth: '200px' }}
                    >
                      PRODUCT NAME
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      SELLER
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      PRICE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="priority"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      BOOST
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.productList || []).length > 0 ? (
                    (this.state.productList || []).map((method, index) => (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={`product-${index}`}>
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input
                              className="form-check-input"
                              onClick={() => this.selectBulk(method._id)}
                              type="checkbox"
                              data-bulk-select-row={method._id}
                            />
                          </div>
                        </td>
                        <td className="customer align-middle">{formatDateTime(method.created_time * 1000)}</td>
                        <td className="customer align-middle">
                          <a
                            className="d-flex align-items-center text-body text-hover-1000"
                            href="#!"
                            data-bs-toggle="modal"
                            data-bs-target="#scrollingLong"
                            onClick={() => this.setProduct(method)}
                          >
                            <h6 className="username mb-0 fw-semibold">{method.name}</h6>
                          </a>
                          <div>
                            <a
                              className="username mb-0 text-lowercase"
                              href={`https://playswap.gg/${method.category_detail.name}/${method.product_type_detail.name}/${method.slug}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <strong>
                                {method.category_detail.name}/{method.product_type_detail.name}
                              </strong>
                            </a>
                          </div>
                        </td>
                        <td className="customer align-middle white-space-nowrap text-center">
                          <h6 className="username mb-0 fw-semibold">{method.seller_info.seller_name}</h6>
                          <div>
                            {getUserId(method.created_by)} | {method.seller_info.seller_rank}
                          </div>
                        </td>
                        <td className="customer align-middle white-space-nowrap text-center">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertCurrency(method.price, true, this.props.settings?.data?.sgd),
                            }}
                          />
                        </td>
                        <td className="priority align-middle white-space-nowrap text-center">
                          {method.priority > 1 ? '1' : '0'}
                        </td>

                        <td className="joined align-middle white-space-nowrap text-body-tertiary text-end">
                          <button
                            className="btn btn-primary ms-1 btn-sm px-2"
                            onClick={() => {
                              this.setState({ selectedProduct: method }, () => {
                                this.handleBoost();
                              });
                            }}
                          >
                            <i className="fa-solid fa-thumbtack me-1"></i> Boost
                          </button>
                          <button
                            className="btn btn-danger ms-1 btn-sm px-2"
                            onClick={() => this.handleDelete(method._id)}
                          >
                            <i className="fa-solid fa-trash me-1"></i>Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9 mt-3">
              <div className="col-auto d-flex">
                <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
                  View {this.state.filter.page * this.state.filter.page_size} of {this.state.total_items}
                </p>
              </div>
              <div className="col-auto d-flex">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / this.state.filter.page_size)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scrollingLong"
          tabIndex="-1"
          aria-labelledby="scrollingLongModalLabel"
          aria-hidden="true"
        >
          {this.state.product && (
            <div className="modal-dialog" style={{ maxWidth: '70%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title fw-semibold" id="scrollingLongModalLabel">
                    {this.state.product?.name}
                  </div>
                  <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span className="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-6">
                    {(this.state.product?.collection_url || []).length > 0 &&
                      (this.state.product?.collection_url || []).map((el, index) => (
                        <div className="col" key={`p-${index}`}>
                          <img src={el} style={{ width: '100px' }} />
                        </div>
                      ))}
                  </div>
                  <div className="mb-6">
                    {/* <h4 className="mb-3">Description</h4> */}
                    <div dangerouslySetInnerHTML={{ __html: this.state.product?.description }}></div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Products.propTypes = {
  auth: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  getProductLists: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  products: state.products,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getProductLists,
  deleteProduct,
  boostProduct,
  getSellerLists,
  deleteBulkProduct,
})(withRouter(Products));
